import { TypographyType } from '@platform-ui-kit/components-library/dist/types/components/wpp-typography/types'
import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'components/common/noData/NoData.module.scss'

interface Props {
  type?: TypographyType
}

export const NoData = ({ type = 'l-strong' }: Props) => (
  <WppTypography type={type} className={styles.noData}>
    -
  </WppTypography>
)
