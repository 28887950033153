import { projectApi } from 'api'
import { ProjectRole } from 'types/permissions/permissions'
import { ProjectMember } from 'types/projects/projectMembers'

interface UpdateProjectMember {
  email: string
  projectId: string
  role: ProjectRole
}

export const updateProjectMemberApi = (body: UpdateProjectMember) => projectApi.patch<ProjectMember[]>('/members', body)
