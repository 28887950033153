import { IRowNode } from 'ag-grid-community/dist/types/core/interfaces/iRowNode'

import { Members } from 'types/members/members'

export const nameSortComparator = (
  valueA: string,
  valueB: string,
  nodeA: IRowNode<Members>,
  nodeB: IRowNode<Members>,
) => {
  const userA = nodeA.data
  const userB = nodeB.data
  const lastnameA = userA?.lastname || userA?.wrikeExternalLastName || ''
  const lastnameB = userB?.lastname || userB?.wrikeExternalLastName || ''

  return lastnameA.toLowerCase().localeCompare(lastnameB.toLowerCase())
}

export const stringSortComparator = (valueA: string, valueB: string) => {
  return (valueA ?? '').toLowerCase().localeCompare((valueB ?? '').toLowerCase())
}
