import { useCallback, useMemo } from 'react'

import { ACTION_ANALYTICS, CanvasItemType, WorkflowActionType } from 'constants/analytics'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useProject } from 'hooks/useProject'
import { useTimeTracker } from 'hooks/useTimeTracker'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { ProjectRole } from 'types/permissions/permissions'

const useWorkflowEditAnalytics = () => {
  const projectContext = useProject()
  const { me } = useHasProjectRole()
  const { trackAction } = useTrackAction()
  const { calculateTimeDifferenceInSeconds } = useTimeTracker()

  const commonAnalyticsData = useMemo(
    () => projectContext?.project && getCommonProjectAnalyticsData(projectContext?.project, me),
    [projectContext?.project, me],
  )

  const trackWorkflowAction = useCallback(
    (action: WorkflowActionType, additionalData = {}) => {
      const analyticsData = {
        ...commonAnalyticsData,
        ...additionalData,
      }
      trackAction(action, analyticsData)
    },
    [commonAnalyticsData, trackAction],
  )

  const trackItemAction = useCallback(
    (action: WorkflowActionType, type: CanvasItemType, additionalData = {}) => {
      trackWorkflowAction(action, { type, ...additionalData })
    },
    [trackWorkflowAction],
  )

  const trackItemAdd = useCallback(
    ({ type, numberOfApps, skipDuration }: { type: CanvasItemType; numberOfApps: number; skipDuration?: boolean }) => {
      trackItemAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_ADD, type, {
        number_of_items: numberOfApps,
        duration_in_seconds: skipDuration ? 0 : calculateTimeDifferenceInSeconds(),
      })
    },
    [calculateTimeDifferenceInSeconds, trackItemAction],
  )

  const trackItemMove = useCallback(
    (type: CanvasItemType) => {
      trackItemAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_MOVE, type)
    },
    [trackItemAction],
  )

  const trackItemDelete = useCallback(
    (type: CanvasItemType) => {
      trackItemAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_DELETE, type)
    },
    [trackItemAction],
  )

  const trackConnectionRemoved = useCallback(() => {
    trackWorkflowAction(ACTION_ANALYTICS.ACTION_WORKFLOW_CONNECTION_REMOVED)
  }, [trackWorkflowAction])

  const trackConnectionAdded = useCallback(
    (sourceItemType?: string, targetItemType?: string) => {
      trackWorkflowAction(ACTION_ANALYTICS.ACTION_WORKFLOW_CONNECTION_ADDED, {
        source_item_type: sourceItemType,
        target_item_type: targetItemType,
      })
    },
    [trackWorkflowAction],
  )

  const trackItemVisibilityChange = useCallback(
    (type: CanvasItemType, eventSource?: string) => {
      trackWorkflowAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_VISIBILITY_CHANGE, {
        type,
        event_source: eventSource,
      })
    },
    [trackWorkflowAction],
  )

  const trackItemEdit = useCallback(
    ({
      type,
      assignUser,
      trackDuration,
      eventSource,
    }: {
      type: CanvasItemType
      assignUser?: ResponsibleUser
      trackDuration?: boolean
      eventSource?: string
    }) => {
      trackWorkflowAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_EDIT, {
        type,
        responsible_person_role: !assignUser?.isMember ? ProjectRole.VIEWER : assignUser.role,
        event_source: eventSource,
        ...(trackDuration && { duration_in_seconds: calculateTimeDifferenceInSeconds() }),
      })
    },
    [calculateTimeDifferenceInSeconds, trackWorkflowAction],
  )

  const trackItemEditCancelled = useCallback(
    (type: CanvasItemType, eventSource?: string) => {
      trackWorkflowAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_EDIT_CANCELLED, {
        type,
        duration_in_seconds: calculateTimeDifferenceInSeconds(),
        event_source: eventSource,
      })
    },
    [calculateTimeDifferenceInSeconds, trackWorkflowAction],
  )

  return {
    trackItemAdd,
    trackItemMove,
    trackItemDelete,
    trackConnectionRemoved,
    trackConnectionAdded,
    trackItemEdit,
    trackItemEditCancelled,
    trackItemVisibilityChange,
  }
}

export default useWorkflowEditAnalytics
