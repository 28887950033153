import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ACTION_ANALYTICS } from 'constants/analytics'
import { PINBOARD_URL } from 'constants/pinboard-config'
import { useTrackAction } from 'hooks/useAnalytics'
import { useProject } from 'hooks/useProject'
import { PenpalParentFrame } from 'pages/project/components/penpalParentFrame/PenpalParentFrame'
import styles from 'pages/project/components/pinboard/Pinboard.module.scss'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { routesManager } from 'utils/routesManager'

export const Pinboard = () => {
  const { t } = useTranslation()
  const { project } = useProject()
  const { me } = useHasProjectRole()
  const { trackAction } = useTrackAction()

  const parentUrl = useMemo(() => {
    return `orchestration${routesManager.project.pinboard.getURL({
      id: project.id,
    })}`
  }, [project.id])

  useEffect(() => {
    trackAction(ACTION_ANALYTICS.ACTION_VIEW_PROJECT_PINBOARD, {
      project_type: project.type,
      process_type: project.processType,
      user_role: me?.role,
    })
  }, [me?.role, project.processType, project.type, trackAction])

  return (
    <PenpalParentFrame
      className={styles.iframeRoot}
      src={PINBOARD_URL}
      title={t('pinboard.title')}
      parentUrl={parentUrl}
    />
  )
}
