import { TenantType } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { HierarchyTreeNode } from 'api/hubs/fetchers/utils'
import { useGetHubsInfo } from 'api/hubs/queries/useGetHubsInfo'
import { useFetchNavigationNodeParents } from 'api/tenant/queries/useFetchNavigationNodeParents'
import { removeObsoleteData, useLocalStorage } from 'hooks/useLocalStorage'
import { usePreselectedHierarchy } from 'hooks/usePreselectedHierarchy'
import { LocalStorageKey } from 'types/common/localStorage'
import { ProjectOwnership, ProjectStatus } from 'types/projects/projects'
import { TaskDueDate } from 'types/projects/tasks'
import { resolveHierarchyPath } from 'utils/coreContextValues'

interface Props<T extends Record<string, any>> {
  initState: T
  lsCode: LocalStorageKey.DASHBOARD_FILTERS | LocalStorageKey.DASHBOARD_TASKS_FILTERS
}

const getNodeType = (node: HierarchyTreeNode) =>
  (node?.type === HierarchyCustomNodeType ? node.customTypeName : node?.type)?.toLocaleLowerCase() as string

export const useFilters = <T extends Record<string, any>>({
  initState,
  lsCode,
}: Props<T>): {
  isLoading: boolean
  state: T
} => {
  const {
    osContext: { userDetails, tenant, navigationTree },
  } = useOs()

  const hubIdRef = useRef<URLSearchParams | null>(null)
  const [resetFilter, setResetFilter] = useState<boolean | null>(null)
  const [params, setParams] = useSearchParams()
  const isAgencyWorkspace = tenant.tenantType === TenantType.Agency

  // TODO task  to remove next 2 lines - https://jira.uhub.biz/browse/WPPOPENWM-926
  removeObsoleteData(`${LocalStorageKey.DASHBOARD_FILTERS_OLD}:${userDetails.id}`)
  removeObsoleteData(`${LocalStorageKey.DASHBOARD_TASKS_FILTERS_OLD}:${userDetails.id}`)
  const [lsFiltersState] = useLocalStorage<T>(`${lsCode}:${userDetails.id}`, {} as T)
  const isLsFiltersEmpty = useMemo(() => Object.keys(lsFiltersState).length === 0, [lsFiltersState])

  const { hierarchy: preselectedHierarchy, isLoading: isPreselectedLoading } = usePreselectedHierarchy({
    clientMapping: navigationTree.mapping,
    tenantId: tenant.id,
    enabled: isLsFiltersEmpty,
  })

  useLayoutEffect(() => {
    const resetFilters = params.get('resetFilters')?.toLowerCase() === 'true'
    const hubId = params.get('hubId')

    setResetFilter(resetFilters)

    if (hubId && !resetFilters) {
      hubIdRef.current = params
    }

    const updatedParams = new URLSearchParams(params)
    updatedParams.delete('resetFilters')
    updatedParams.delete('hubId')

    setParams(updatedParams)
  }, [params, setParams])

  const { data: hub, isLoading: isHubLoading } = useGetHubsInfo({
    params: { hubId: hubIdRef.current?.get('hubId')! },
    enabled: !!hubIdRef.current?.get('hubId') && !isAgencyWorkspace,
  })

  const { data: hubHierarchyTree, isLoading: isHubHierarchyLoading } = useFetchNavigationNodeParents({
    params: { tenantId: hub?.tenantId!, nodeId: hub?.organizationUnitId! },
    enabled: !!hub && !isAgencyWorkspace,
  })

  const hubFilters = useMemo(() => {
    if (isAgencyWorkspace) return {}
    if (!hubHierarchyTree) return {}

    if (!hub && !hubHierarchyTree.mapping) return null
    const rootNode = hubHierarchyTree.mapping[hub?.organizationUnitId!]

    if (rootNode && rootNode.azId === hubHierarchyTree.rootId) return null

    const hubHierarchyIds = resolveHierarchyPath({
      validHierarchyId: hub?.organizationUnitId ?? null,
      navigationTree: hubHierarchyTree,
    })

    // get filters for Workspace
    const allOptionsToSelect = [...hubHierarchyIds].reduce((acc, nodeId) => {
      const node = hubHierarchyTree.mapping[nodeId]
      const nodeType = getNodeType(node as HierarchyTreeNode)
      return { ...acc, [nodeType]: nodeId }
    }, {} as Partial<T>)

    // All other filters
    const { hubId, ...rest } = Object.fromEntries(hubIdRef.current!) as { hubId: string } & T
    const allOtherFilters = Object.keys(rest).reduce((acc, key) => {
      if (key in initState) {
        // For projects filter
        if (key === 'ownership' && !(rest[key] in ProjectOwnership)) return acc

        if (key === 'status') {
          acc[key] = [...hubIdRef.current!.getAll(key).filter((status: string) => status in ProjectStatus)]
          return acc
        }

        if (key === 'type') {
          acc[key] = [...hubIdRef.current!.getAll(key)]
          return acc
        }

        // For tasks filter
        if (key === 'selectedProjects') {
          acc[key] = hubIdRef.current!.getAll(key)
          return acc
        }
        if (key === 'dueDateRanges') {
          acc[key] = [...hubIdRef.current!.getAll(key).filter((range: string) => range in TaskDueDate)]
          return acc
        }

        acc[key] = rest[key]
      }

      return acc
    }, {} as Record<string, any>)

    return { workspace: hubHierarchyIds, ...allOtherFilters, ...allOptionsToSelect }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hub, hubHierarchyTree])

  return useMemo(
    () => {
      const isPreselectedEmpty = Object.keys(preselectedHierarchy).length === 0

      const localstorageFilters =
        !isPreselectedEmpty && isLsFiltersEmpty
          ? {
              ...preselectedHierarchy,
              workspace: Object.values(preselectedHierarchy).flat(),
            }
          : lsFiltersState

      const filtersLoading =
        (!!hubIdRef.current?.get('hubId') && (isHubLoading || isHubHierarchyLoading)) ||
        resetFilter === null ||
        isPreselectedLoading

      return {
        isLoading: filtersLoading,
        state: resetFilter
          ? initState
          : {
              ...initState,
              ...(!!hubIdRef.current ? hubFilters : localstorageFilters),
            },
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hubFilters, isHubHierarchyLoading, isHubLoading, lsFiltersState, resetFilter, isPreselectedLoading],
  )
}
