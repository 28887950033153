import { useHierarchy } from 'hooks/useHierarchy'
import { HierarchySelect } from 'pages/components/projectModal/components/hierarchySelect/HierarchySelect'

export const HierarchyFilterControl = () => {
  const { hierarchyOrder } = useHierarchy()

  // TODO restore WITHOUT_HIERARCHY option when BE will support it
  return hierarchyOrder?.map((hierarchy, index) => {
    return <HierarchySelect key={hierarchy} index={index} hierarchy={hierarchy} controlPrefix="" />
  })
}
