import { WppActionButton, WppButton, WppIconReset, WppTypography } from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useHierarchy } from 'hooks/useHierarchy'
import { MultiSelectAttribute } from 'pages/components/projectModal/components/attributes/MultiSelectAttribute'
import { SingleSelectAttribute } from 'pages/components/projectModal/components/attributes/SingleSelectAttribute'
import { HierarchyFilterControl } from 'pages/dashboard/components/hierarchyFilterControl/HierarchyFilterControl'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import styles from 'pages/project/components/files/Files.module.scss'
import { datesRangeMapping } from 'pages/project/components/tasks/utils'
import { ProjectAttribute, ProjectAttributeType } from 'types/projects/attributes'
import { TasksFilter } from 'types/projects/tasks'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  filters: TasksFilter
  withHierarchy?: boolean
  attributes?: ProjectAttribute[]
  onFiltersSave: (filters: TasksFilter) => void
}

export const TasksFiltersModal = ({
  isOpen,
  onClose,
  onCloseComplete,
  filters,
  onFiltersSave,
  withHierarchy,
  attributes,
}: Props) => {
  const form = useForm({ defaultValues: filters })
  const { osContext } = useOs()
  const { hierarchyOrder } = useHierarchy()
  const [, clearParams] = useSearchParams()

  const isAgencyWorkspace = osContext.tenant.tenantType === TenantType.Agency
  const { t } = useTranslation()

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
  } = form

  const onSubmit = handleSubmit(value => {
    if (withHierarchy) {
      const hierarchyFilters = Object.entries(value)
        .filter(([key, value]) => hierarchyOrder.includes(key) && !!value?.length)
        .map(([, value]) => value)
      const hasHierarchySet = !!hierarchyFilters.length

      if (!hasHierarchySet) {
        onFiltersSave({ ...value, workspace: undefined, includeEmptyWorkspace: false })
        onClose()
        return
      }

      onFiltersSave({ ...value, workspace: hierarchyFilters, includeEmptyWorkspace: false })
      clearParams()
      onClose()
    } else {
      onFiltersSave(value)
      clearParams()
      onClose()
    }
  })

  const dueDatesOptions = useMemo(() => {
    return Object.entries(datesRangeMapping).map(([key, value]) => ({
      value: key,
      label: t(value),
    }))
  }, [t])

  const onReset = () => {
    reset({
      ...filters,
      dueDateRanges: [],
      workspace: [],
      attributes: attributes?.reduce((acc, curr) => ({ ...acc, [curr.contractName]: [] }), {}),
      ...(withHierarchy &&
        hierarchyOrder.reduce(
          (pre, curr) => ({
            ...pre,
            [curr]: '',
          }),
          {},
        )),
    })
  }

  const watchAllFields = watch()
  const {
    search,
    attributes: projectAttributes,
    selectedProjects,
    selectedStatuses,
    ...allOtherFields
  } = watchAllFields

  const filtersCounts = useMemo(() => {
    return getAppliedFilters({ ...allOtherFields, ...projectAttributes })
  }, [allOtherFields, projectAttributes])

  return (
    <FormProvider {...form}>
      <SideModal
        size="s"
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        data-testid="tasks-filter-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('project.tasks.filters.title')}
        </WppTypography>
        <div slot="body">
          <Flex direction="column" className={styles.filtersWrapper} gap={16}>
            <FormSelect
              name="dueDateRanges"
              type="multiple"
              data-testid="due-date-select"
              options={dueDatesOptions}
              labelConfig={{ text: t('project.tasks.filters.field_type_label') }}
              placeholder={t('project.tasks.filters.field_type_placeholder')!}
              required
              withFolder
              withSearch
            />
            {withHierarchy && !isAgencyWorkspace && <HierarchyFilterControl />}
            {attributes?.map(attribute => {
              switch (attribute.type) {
                case ProjectAttributeType.SINGLE_SELECT:
                  return (
                    <SingleSelectAttribute formGroup="attributes" key={attribute.id} attribute={attribute} isFilter />
                  )
                case ProjectAttributeType.MULTI_SELECT:
                  return (
                    <MultiSelectAttribute formGroup="attributes" key={attribute.id} attribute={attribute} isFilter />
                  )
                default:
                  return null
              }
            })}
          </Flex>
        </div>
        <Flex justify="between" slot="actions">
          <Flex>
            {filtersCounts > 0 && (
              <WppActionButton variant="primary" onClick={onReset}>
                <WppIconReset className={styles.resetIcon} />
                {t('common.btn_reset')}
              </WppActionButton>
            )}
          </Flex>
          <Flex gap={12}>
            <WppButton size="m" variant="secondary" onClick={onClose}>
              {t('common.btn_cancel')}
            </WppButton>
            <WppButton size="m" type="submit" variant="primary" loading={isSubmitting}>
              {t('common.btn_apply')}
            </WppButton>
          </Flex>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showTasksFiltersModal } = createNiceModal<Props>(TasksFiltersModal, 'tasks-filters-modal')
