import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useAssignMember } from 'hooks/useAssignMember'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import styles from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsModal.module.scss'
import { Phase } from 'types/projects/workflow'
import { isPhaseStatusResolved } from 'utils/project'

interface Props {
  phase: Phase
}

export const PhaseDetailsModalHeader = ({ phase }: Props) => {
  const { t } = useTranslation()
  const assignMember = useAssignMember(phase?.assignUser)
  const isResolved = isPhaseStatusResolved(phase)

  return (
    <Flex direction="column" gap={10}>
      <WppTypography type="l-strong" data-testid="phase-name">
        {phase.name}
      </WppTypography>
      <Flex direction="column" gap={12}>
        <Flex align="center" className={styles.infoBlock}>
          <WppTypography type="s-strong" className={styles.title}>
            {t('modals.phase_details.status')}
          </WppTypography>
          <WppTypography type="s-strong" className={styles.greyColor900}>
            {t(`project.phase.status.${phase.status}`)}
          </WppTypography>
        </Flex>
        <Flex align="center" className={styles.infoBlock}>
          <WppTypography type="s-strong" className={styles.title}>
            {t('modals.phase_details.assignee')}
          </WppTypography>
          {!assignMember ? (
            <WppTypography type="s-strong" className={styles.greyColor500}>
              {t('modals.phase_details.no_assignee')}
            </WppTypography>
          ) : (
            <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="application-item-assignee" showName />
          )}
        </Flex>
        <Flex align="center" className={styles.infoBlock}>
          <WppTypography type="s-strong" className={styles.title}>
            {t('modals.phase_details.due_date')}
          </WppTypography>
          {phase?.startDate || phase?.endDate ? (
            <Calendar
              startDate={phase?.startDate}
              endDate={phase.endDate}
              data-testid="application-item-dates"
              resolved={isResolved}
            />
          ) : (
            <WppTypography type="s-strong" className={styles.greyColor500}>
              {t('modals.phase_details.no_due_date')}
            </WppTypography>
          )}
        </Flex>

        {phase.description && (
          <Flex direction="column">
            <WppTypography type="s-strong" className={styles.title}>
              {t('modals.phase_details.description')}
            </WppTypography>
            <Truncate
              lines={3}
              type="s-body"
              className={styles.greyColor1000}
              data-testid="description"
              title={phase.description}
            >
              {phase.description}
            </Truncate>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
