import { projectApi } from 'api'

interface Props {
  search?: string
}

export interface ProjectTypesDTO {
  name: string
  requiresHierarchy: boolean
}

export const fetchProjectTypes = ({ search }: Props) =>
  projectApi.get<{ data: ProjectTypesDTO[] }>('/projects/types', { params: { ...(search?.length && { search }) } })
