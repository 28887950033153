import { useState } from 'react'
import { useEvent } from 'react-use'

import { useStableCallback } from 'hooks/useStableCallback'
import { isFunction } from 'utils/common'

export function useLocalStorage<T>(key: string, initialValue: T) {
  const customStorageEventName = 'wpp-local-storage'

  const read = useStableCallback(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? (JSON.parse(item) as T) : initialValue
    } catch {
      // Browser is in private mode
      return initialValue
    }
  })

  const [state, setState] = useState(read)

  const updater = useStableCallback((value: T | ((prev: T) => T)) => {
    try {
      const nextState = isFunction(value) ? value(state) : value
      window.localStorage.setItem(key, JSON.stringify(nextState))
      setState(nextState)
      window.dispatchEvent(new CustomEvent(customStorageEventName))
    } catch {
      // Browser is in private mode
    }
  })

  const onStorageChange = useStableCallback((e: Event) => {
    const isStorageEvent = (value: Event): value is StorageEvent => e.type === 'storage'

    if (isStorageEvent(e) && e.key !== key) {
      return
    }

    setState(read())
  })

  useEvent('storage', onStorageChange)
  useEvent(customStorageEventName, onStorageChange)

  return [state, updater] as const
}

// technical helper, to cleanup obsolete data from LS
export const removeObsoleteData = (key: string) => {
  try {
    window.localStorage.removeItem(key)
  } catch {
    // Browser is in private mode
  }
}
