import { HierarchyNodeType } from '@wpp-open/core/types/mapping/common'
import { getChildHierarchyLevels } from '@wpp-open/core/utils/hierarchy'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { ContextHierarchy } from 'types/projects/projects'

export interface AnalyticsWorkspaceTree {
  name: string
  type: HierarchyNodeType | 'PROJECT' | 'CONTAINER' | string
}

export const useHierarchy = () => {
  const {
    osContext: { tenant },
  } = useOs()

  const hierarchyOrder = useMemo(() => getChildHierarchyLevels(tenant).map(({ type }) => type.toLowerCase()), [tenant])

  const mapEntitiesText = (entities: ContextHierarchy[]): string => {
    return entities
      .filter(entity => entity.name) // Remove entities with name as null or empty
      .map(entity => {
        const entityType = entity.title.charAt(0).toUpperCase() + entity.title.slice(1)
        return `${entityType}: ${entity.name}`
      })
      .join(' / ')
  }

  const sortHierarchy = (entities: ContextHierarchy[]): ContextHierarchy[] => {
    return entities.toSorted((a, b) => {
      return hierarchyOrder.indexOf(a.title) - hierarchyOrder.indexOf(b.title)
    })
  }

  return { hierarchyOrder, mapEntitiesText, sortHierarchy }
}
