import {
  InlineEditChangeModeEventDetail,
  InlineEditMode,
  InputChangeEventDetail,
} from '@platform-ui-kit/components-library'
import { WppInlineEditCustomEvent, WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppInlineEdit } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, ReactNode, useState, cloneElement, ReactElement } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { useField } from 'hooks/form/useField'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppInlineEdit>, 'value' | 'onChange' | 'onWppChange'> {
  name: string
  size?: 's' | 'm'
  placeholder?: string
  inputElement: ReactNode
}

export const FormInlineEdit = forwardRef<HTMLWppInlineEditElement, Props>(
  ({ name, placeholder, size = 'm', inputElement, ...rest }) => {
    const [inputMode, setInputMode] = useState<InlineEditMode>('read')

    const {
      field: { value, onChange, onBlur },
    } = useField({ name })

    return (
      <Flex direction="column" gap={4}>
        <WppInlineEdit
          data-testid="form-inline-edit"
          value={value}
          mode={inputMode}
          placeholder={placeholder}
          onWppModeChange={(event: WppInlineEditCustomEvent<InlineEditChangeModeEventDetail>) => {
            setInputMode(event.detail.mode)
            if (event.detail.mode === 'read') {
              event.detail.closePopover()
              onBlur()
            }
          }}
          {...rest}
        >
          {cloneElement(inputElement as ReactElement, {
            value,
            size,
            placeholder,
            onWppChange: (e: WppInputCustomEvent<InputChangeEventDetail>) => onChange(e.detail.value),
          })}
        </WppInlineEdit>
      </Flex>
    )
  },
)
