import { HierarchyContainerNodeId } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useFetchTenantHierarchy } from 'api/tenant/queries/useFetchNavigationTree'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { useHierarchy } from 'hooks/useHierarchy'
import styles from 'pages/components/projectModal/components/projectForm/ProjectForm.module.scss'
import { capitalizeFirstLetter } from 'utils/common'

interface Props {
  index: number
  hierarchy: string
  controlPrefix?: string
}

export const HierarchySelect = ({ index, hierarchy, controlPrefix = 'hierarchy.' }: Props) => {
  const { t } = useTranslation()
  const {
    osContext: { tenant, navigationTree },
  } = useOs()
  const { hierarchyOrder } = useHierarchy()
  const { watch, setValue } = useFormContext()

  const isFirstHierarchy = hierarchyOrder[0] === hierarchy
  const firstLevelHierarchyOption = useMemo(() => {
    return (
      navigationTree.mapping[HierarchyContainerNodeId].children
        .map(key => navigationTree.mapping[key])
        // @TODO: remove this after fix core hierarchy
        .filter(h => h.type === 'CLIENT')
        .map(({ azId, name }) => ({ value: azId!, label: name }))
        .sort((a, b) => a.label.localeCompare(b.label))
    )
  }, [navigationTree.mapping])

  const nodeIdFromAboveHierarchy = isFirstHierarchy ? '' : watch(`${controlPrefix}${hierarchyOrder[index - 1]}`)
  const { data, isLoading } = useFetchTenantHierarchy({
    params: { tenantId: tenant.id, parentId: nodeIdFromAboveHierarchy },
    enabled: !isFirstHierarchy && !!nodeIdFromAboveHierarchy,
  })

  const currentLevelOptions = useMemo(
    () =>
      Object.values(data?.mapping || {}).map(h => ({
        value: h.azId!,
        label: h.name,
      })),
    [data],
  )

  const setDisable = useCallback(() => {
    if (isFirstHierarchy) return false

    return !nodeIdFromAboveHierarchy || !currentLevelOptions.length
  }, [currentLevelOptions.length, isFirstHierarchy, nodeIdFromAboveHierarchy])

  return (
    <FormSelect
      name={`${controlPrefix}${hierarchy}`}
      withSearch
      data-testid={`${hierarchy}-select`}
      options={isFirstHierarchy ? firstLevelHierarchyOption : currentLevelOptions}
      loading={isLoading}
      labelConfig={{
        text: t(`modals.create_project.field_${hierarchy}_label`, {
          defaultValue: capitalizeFirstLetter(hierarchy),
        }),
      }}
      placeholder={
        t(`modals.create_project.field_${hierarchy}_placeholder`, {
          defaultValue: capitalizeFirstLetter(hierarchy),
        })!
      }
      required
      toggleSelection
      disabled={setDisable()}
      className={styles.hierarchySelect}
      onWppChange={() => {
        for (let i = index + 1; i < hierarchyOrder.length; i++) setValue(`${controlPrefix}${hierarchyOrder[i]}`, '')
      }}
    />
  )
}
