import { WppSpinner } from '@platform-ui-kit/components-library-react'
import { FramedAppMethods, FramedAppParentMethods } from '@wpp-open/core'
import { connectToChild, Methods } from 'penpal'
import { ComponentPropsWithoutRef, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from 'hooks/useToast'
import { useProjectChildContext } from 'pages/project/components/penpalParentFrame/useProjectChildContext'

type Props = ComponentPropsWithoutRef<'iframe'> & {
  parentUrl?: string
}

export const PenpalParentFrame = ({ parentUrl, ...iframeProps }: Props) => {
  const ref = useRef<HTMLIFrameElement>(null)
  const { osApi, osContext, isError } = useProjectChildContext({ parentUrl })
  const { t } = useTranslation()
  const { showToast } = useToast()

  useEffect(() => {
    if (isError) {
      showToast({ type: 'error', message: t('common.generic_error')! })
    }
  }, [isError, showToast, t])

  useEffect(() => {
    if (!osContext) return

    const parentMethods: Methods = {
      osApi: { ...osApi },
    } satisfies FramedAppParentMethods

    const connection = connectToChild<FramedAppMethods>({
      iframe: ref.current!,
      methods: parentMethods,
      debug: false,
    })

    connection.promise
      .then(child => {
        return child.receiveOsContext(osContext)
      })
      .catch(error => {
        console.error("Penpal parent context error':", error)
      })

    return () => {
      connection.destroy()
    }
  }, [osApi, osContext])

  if (!osContext) {
    return <WppSpinner size="l" style={{ margin: 'auto' }} />
  }

  // eslint-disable-next-line jsx-a11y/iframe-has-title
  return <iframe ref={ref} {...iframeProps} />
}
