import {
  AutocompleteOption,
  AutocompleteOptionId,
  GetOptionIdHandler,
  GetOptionLabelHandler,
  AutocompleteChangeEventDetail,
} from '@platform-ui-kit/components-library'
import { WppAutocomplete, WppListItem, WppPill } from '@platform-ui-kit/components-library-react'
import { ComponentProps, forwardRef, ReactNode, Ref, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import styles from 'components/common/autocomplete/Autocomplete.module.scss'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { useStableCallback } from 'hooks/useStableCallback'

export type AutocompleteProps<T extends AutocompleteOption = AutocompleteOption> = Omit<
  ComponentProps<typeof WppAutocomplete>,
  'getOptionId' | 'getOptionLabel' | 'value' | 'children'
> & {
  'data-testid'?: string
  value: T[]
  options: T[]
  getOptionId?: (option: T) => AutocompleteOptionId
  getOptionLabel?: (option: T) => string
  renderOptionContent?: (option: T) => ReactNode
  renderPillContent?: (value: T) => ReactNode
}

export type AutocompleteDropdownConfig = NonNullable<AutocompleteProps['dropdownConfig']>

const defaultGetOptionIdHandler = <T extends AutocompleteOption>(option: T) => option.id
const defaultGetOptionLabelHandler = <T extends AutocompleteOption>(option: T) => option.label

export const Autocomplete = forwardRef(function Autocomplete<T extends AutocompleteOption>(
  {
    options,
    getOptionId = defaultGetOptionIdHandler,
    getOptionLabel = defaultGetOptionLabelHandler,
    renderPillContent = getOptionLabel,
    renderOptionContent,
    value: values,
    showCreateNewElement = false,
    type = 'extended',
    ...rest
  }: Omit<AutocompleteProps<T>, 'ref'>,
  ref: Ref<HTMLWppAutocompleteElement>,
) {
  const innerRef = useRef<HTMLWppAutocompleteElement>(null)

  const onPillRemove = useStableCallback((value: T) => {
    innerRef.current?.dispatchEvent(
      new CustomEvent<AutocompleteChangeEventDetail>('wppChange', {
        bubbles: false,
        composed: false,
        detail: {
          value: values.filter(v => getOptionId(v) !== getOptionId(value)),
          reason: 'removeOption',
        },
      }),
    )
  })

  return (
    <WppAutocomplete
      ref={mergeRefs([innerRef, ref])}
      {...rest}
      type={type}
      value={values}
      showCreateNewElement={showCreateNewElement}
      getOptionId={getOptionId as GetOptionIdHandler}
      getOptionLabel={getOptionLabel as GetOptionLabelHandler}
    >
      {options.map(option => (
        <WppListItem key={getOptionId(option)} value={option}>
          {renderOptionContent ? (
            renderOptionContent?.(option)
          ) : (
            <>
              <Avatar slot="left" name={getOptionLabel(option)} src={option.avatar ?? ''} />
              <span slot="label" data-testid="autocomplete-label">
                {getOptionLabel(option)}
              </span>
              {option.caption && (
                <span slot="caption" data-testid="autocomplete-caption">
                  {option.caption}
                </span>
              )}
            </>
          )}
        </WppListItem>
      ))}

      <Flex className={styles.value} slot="selected-values" gap={8} wrap="wrap">
        {values.map(value => (
          <WppPill
            key={getOptionId(value)}
            removable
            type="display"
            onWppClose={() => {
              onPillRemove(value)
            }}
          >
            {renderPillContent(value)}
          </WppPill>
        ))}
      </Flex>
    </WppAutocomplete>
  )
}) as <T extends AutocompleteOption>(props: AutocompleteProps<T>) => JSX.Element
