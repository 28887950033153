import { WppButton, WppInlineMessage, WppTypography } from '@platform-ui-kit/components-library-react'
import { Fragment, useMemo, useRef } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import styles from 'pages/settings/integrations/components/statusMappingModal/StatusMappingModal.module.scss'
import { osStatuses, useMappingForm, useSaveForm } from 'pages/settings/integrations/components/statusMappingModal/util'
import { WrikeWorkflow } from 'types/integrations/integration'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

export interface Props extends NiceModalWrappedProps {
  workflow: WrikeWorkflow
  onCloseAll: () => void
}

const StatusMappingModal = ({ workflow, isOpen, onCloseComplete, onClose, onCloseAll }: Props) => {
  const { t } = useTranslation()
  const bodyRef = useRef(null)

  const isNew = !workflow.osWorkflow?.id
  const form = useMappingForm(workflow)
  const saveForm = useSaveForm(workflow, onClose)

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onSubmit = handleSubmit(saveForm)

  const osOptions = useMemo(() => {
    return osStatuses.map(status => ({ label: t(`project.tasks.status.${status}`), value: status }))
  }, [t])

  const wrikeOptions = useMemo(() => {
    return workflow.wrikeWorkflow.statuses.map(status => ({ label: status.name, value: status.id }))
  }, [workflow.wrikeWorkflow.statuses])

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{ onSubmit }}
        open={isOpen}
        onWppSideModalClose={() => {
          onClose()
          onCloseAll()
        }}
        onWppSideModalCloseComplete={onCloseComplete}
        size="m"
        withBackButton
        onWppSideModalBackButtonClick={onClose}
      >
        <WppTypography slot="header" type="2xl-heading">
          {isNew ? t('modals.status_mapping_modal.add_title') : t('modals.status_mapping_modal.edit_title')}
        </WppTypography>

        <Flex direction="column" slot="body" gap={24} ref={bodyRef}>
          {!!workflow.error && (
            <WppInlineMessage size="m" message={t('modals.status_mapping_modal.warning')!} type="error" />
          )}

          <WppTypography type="l-strong">{workflow.wrikeWorkflow.name}</WppTypography>

          <div>
            <WppTypography type="s-strong" className="wpp-spacing-8-bottom">
              {t('modals.status_mapping_modal.title_to_wrike')}
            </WppTypography>
            <WppTypography type="s-body" className="wpp-spacing-12-bottom">
              {t('modals.status_mapping_modal.description_to_wrike')}
            </WppTypography>

            <div className={styles.grid}>
              <WppTypography type="s-strong" className={styles.gridHeader}>
                {t('modals.status_mapping_modal.table_header_os')}
              </WppTypography>
              <WppTypography type="s-strong" className={styles.gridHeader}>
                {t('modals.status_mapping_modal.table_header_wrike')}
              </WppTypography>

              {osStatuses.map(status => (
                <Fragment key={status}>
                  <WppTypography className={styles.cell} type="s-body" data-testid={`osWorkflow-label-${status}`}>
                    {t(`project.tasks.status.${status}`)}
                  </WppTypography>
                  <div className={styles.cell}>
                    <FormSelect
                      name={`osWorkflow.${status}`}
                      type="single"
                      options={wrikeOptions}
                      required
                      size="s"
                      showValidationMessage={false}
                      boundaryElement={bodyRef?.current}
                    />
                  </div>
                </Fragment>
              ))}
            </div>
          </div>

          <div>
            <WppTypography type="s-strong" className="wpp-spacing-8-bottom">
              {t('modals.status_mapping_modal.title_to_os')}
            </WppTypography>
            <WppTypography type="s-body" className="wpp-spacing-12-bottom">
              {t('modals.status_mapping_modal.description_to_os')}
            </WppTypography>

            <div className={styles.grid}>
              <WppTypography type="s-strong" className={styles.gridHeader}>
                {t('modals.status_mapping_modal.table_header_wrike')}
              </WppTypography>
              <WppTypography type="s-strong" className={styles.gridHeader}>
                {t('modals.status_mapping_modal.table_header_os')}
              </WppTypography>

              {workflow.wrikeWorkflow.statuses.map(wrikeStatus => (
                <Fragment key={wrikeStatus.id}>
                  <WppTypography
                    className={styles.cell}
                    type="s-body"
                    data-testid={`wrikeWorkflow-label-${wrikeStatus.name}`}
                  >
                    {wrikeStatus.name}
                  </WppTypography>
                  <div className={styles.cell}>
                    <FormSelect
                      name={`wrikeWorkflow.${wrikeStatus.id}`}
                      type="single"
                      options={osOptions}
                      required
                      size="s"
                      showValidationMessage={false}
                      boundaryElement={bodyRef?.current}
                    />
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </Flex>

        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('common.btn_cancel')}
          </WppButton>

          <WppButton variant="primary" size="m" loading={isSubmitting} onClick={onSubmit}>
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showStatusMappingModal } = createNiceModal<Props>(StatusMappingModal, 'status-mapping-modal')
