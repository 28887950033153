import { MayBeNull } from 'types/common/utils'
import { ProjectAttribute } from 'types/projects/attributes'

export interface AttributeProps {
  attribute: ProjectAttribute
  formGroup?: string
  placeholder?: string
  isFilter?: boolean
  boundaryElement?: MayBeNull<HTMLElement>
}

export const getFieldName = (attribute: ProjectAttribute, formGroup?: string) =>
  formGroup ? `${formGroup}.${attribute.contractName}` : attribute.contractName
