import { AutocompleteOption } from '@platform-ui-kit/components-library'
import { WppButton, WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo, useRef, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInviteProjectMemberApi } from 'api/projects/mutation/useInviteProjectMemberApi'
import { useUsersListApi } from 'api/users/queries/useUsersListApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { defaultLocales, FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useMembersAnalytics } from 'hooks/useMembersAnalytics'
import { useProject } from 'hooks/useProject'
import { useTimeTracker } from 'hooks/useTimeTracker'
import { useToast } from 'hooks/useToast'
import { showConfirmExternalModal } from 'pages/project/components/members/components/confirmIExternalModal/ConfirmExternalModal'
import { inviteMembersSchema, roleOption } from 'pages/project/components/members/components/utils'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { Members } from 'types/members/members'
import { ProjectRole } from 'types/permissions/permissions'
import { ExternalMember, ProjectMember, ProjectMemberType } from 'types/projects/projectMembers'
import { fullName } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface ExternalMemberControl extends ExternalMember {
  id: string
}

interface Props extends NiceModalWrappedProps {
  actualMembers: Members[]
}

const defaultValues = {
  members: [] as ProjectMember[],
  externalMembers: [] as ExternalMemberControl[],
  accessType: ProjectMemberType.CONTRIBUTOR,
  message: '',
  role: ProjectRole.VIEWER,
}

const InviteMembersModal = ({ actualMembers, onClose, isOpen, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { project } = useProject()
  const { showToast } = useToast()
  const { trackMemberInvite, trackMemberInviteCancelled } = useMembersAnalytics({ project })
  const { calculateTimeDifferenceInSeconds } = useTimeTracker()

  const [loading, setLoading] = useState(false)

  const { mutateAsync: inviteProjectMembers } = useInviteProjectMemberApi()

  const [usersSearch, setUsersSearch] = useState('')

  const { data: users, isLoading: isUsersLoading } = useUsersListApi({
    params: { search: useDebouncedValue(usersSearch.trim(), 300) },
  })

  const form = useForm({ defaultValues, validationSchema: inviteMembersSchema })
  const {
    handleSubmit,
    formState: { isValid },
    trigger,
    setValue,
    getValues,
  } = form

  const actualMembersMap = useMemo(
    () => Object.fromEntries(actualMembers.map(member => [member.email, member])),
    [actualMembers],
  )

  const usersOptions = useMemo(
    () =>
      users?.data
        ?.map(user => ({
          id: user.id,
          label: fullName(user.firstname, user.lastname),
          caption: user.email,
          email: user.email,
          avatar: user.avatarUrl,
          isExternal: user?.isExternal,
        }))
        .filter(user => !actualMembersMap[user.email]),
    [users.data, actualMembersMap],
  )

  // const { fields, append } = useFieldArray({
  //   control,
  //   name: 'externalMembers',
  // })

  const membersRef = useRef<HTMLWppAutocompleteElement>(null)

  const onSubmit = handleSubmit(async values => {
    if (!isValid) {
      await trigger()
      return
    }

    const { members, role, externalMembers } = values
    const internalMembers = !externalMembers.length
      ? members
      : members?.filter(member => !externalMembers.find(externalMember => externalMember.id === member.id))
    const isAnyExternal = internalMembers.filter(member => member.isExternal)

    const handleInvite = async (idsToFilter?: string[]) => {
      const internal = [
        ...internalMembers.filter(member => !member.isExternal)!,
        ...isAnyExternal.filter(externalMember => idsToFilter?.includes(externalMember.id)),
      ].map(member => ({ email: member?.email }))

      if (internal.length === 0) return

      try {
        setLoading(true)
        await inviteProjectMembers({
          projectId: project.id,
          role,
          internalMembers: internal,
          externalMembers: externalMembers.map(({ id, ...member }) => member),
        })

        await queryClient.invalidateQueries([ApiQueryKeys.MEMBERS])
        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_BY_ID])

        trackMemberInvite({
          totalNumberOfMembers: internal.length,
          totalNumberOfExternalMembers: externalMembers.length,
          invitedMembersRoles: role,
          calculateTimeDifferenceInSeconds,
        })

        onClose()
      } catch (e) {
        showToast({
          type: 'error',
          message: t('modals.invite_member.invite_failed'),
        })
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    if (isAnyExternal.length) {
      showConfirmExternalModal({ members: isAnyExternal, inviteMembers: handleInvite, assign: false })
    } else {
      await handleInvite()
    }
  })

  //@SandiKoren Inviting external users should be restored once external users are automatically added to Okta programmatically.
  // const addExternalMember = (email: string) => {
  //   const { success } = emailSchema.safeParse(email)
  //   if (!success) {
  //     showToast({
  //       type: 'error',
  //       message: t('modals.invite_member.external_user_wrong_email', { email }),
  //     })
  //     return
  //   }

  //   const externalMember: ExternalMemberControl = {
  //     id: String(crypto.randomUUID()),
  //     email,
  //     ...emailToNames(email),
  //     agency: '',
  //   }
  //   append(externalMember)

  //   setValue('members', [
  //     ...getValues('members'),
  //     {
  //       ...externalMember,
  //       firstname: externalMember.firstName,
  //       lastname: externalMember.lastName,
  //       jobTitle: '',
  //     },
  //   ])

  //   // small hack, because `WppAutocomplete` does not allow to clean search or close dropdown programmatically
  //   ;(membersRef.current as any).searchValue = ''
  // }

  const syncExternalMembers = (options: AutocompleteOption[]) => {
    setValue(
      'externalMembers',
      getValues('externalMembers').filter(externalMember => options.find(option => option.id === externalMember.id)),
    )
  }

  const handleCancelEdit = () => {
    onClose()
    trackMemberInviteCancelled(calculateTimeDifferenceInSeconds)
  }

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={handleCancelEdit}
        onWppSideModalCloseComplete={onCloseComplete}
        size="m"
        data-testid="invite-members-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('modals.invite_member.title')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          <Flex gap={12}>
            <FormAutocomplete
              ref={membersRef}
              style={{ flexGrow: 1 }}
              required
              name="members"
              multiple
              placeholder={t('modals.invite_member.field_members_placeholder')!}
              labelConfig={{ text: t('modals.invite_member.field_members_label') }}
              loading={isUsersLoading}
              onWppChange={({ detail }) => {
                if (detail.reason === 'removeOption') {
                  syncExternalMembers(detail.value as AutocompleteOption[])
                }
              }}
              onWppSearchValueChange={event => setUsersSearch(event.detail)}
              options={usersOptions}
              data-testid="members-autocomplete"
              // showCreateNewElement
              // onWppCreateNewOption={({ detail: email }) => addExternalMember(email)}
              renderPillContent={option => (
                <WppTooltip config={{ offset: [0, 10] }} text={option.email}>
                  <Avatar
                    size="xs"
                    name={option.label || option.email}
                    src={option?.avatar ?? ''}
                    style={{ marginRight: '6px' }}
                  />
                  {option.label || option.email}
                </WppTooltip>
              )}
              locales={{
                ...defaultLocales,
              }}
            />

            <FormSelect
              required
              name="role"
              options={roleOption}
              labelConfig={{ text: 'Access' }}
              data-testid="access-select"
            />
          </Flex>

          {/* {!!fields.length && (
            <div>
              <WppTypography type="l-strong">{t('modals.invite_member.external_users_title')}</WppTypography>
              {fields.map(({ id }, index) => (
                <ExternalMemberForm key={id} index={index} />
              ))}
            </div>
          )} */}
        </Flex>

        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={handleCancelEdit}>
            {t('modals.invite_member.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={loading}>
            {t('modals.invite_member.btn_invite')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showInviteMembersModal } = createProjectModal<Props>(
  InviteMembersModal,
  'invite-members-modal',
)
