import { useIsPermitted } from 'hooks/useIsPermitted'
import { AppPermissions } from 'types/permissions/permissions'

export const useCanCreateProject = () => {
  const { isPermitted } = useIsPermitted()

  const canCreateProject =
    isPermitted(AppPermissions.ORCHESTRATION_PROJECTS_CREATE) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const canCreateProjectFromScratch =
    isPermitted(
      [AppPermissions.ORCHESTRATION_PROJECTS_CREATE, AppPermissions.ORCHESTRATION_PROJECTS_CREATE_FROM_SCRATCH],
      true,
    ) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  return { canCreateProjectFromScratch, canCreateProject }
}
