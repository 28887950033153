import {
  WppAvatarGroup,
  WppCard,
  WppDivider,
  WppIconCalendar,
  WppIconEdit,
  WppIconExternalLink,
  WppListItem,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { DefaultHierarchyLevelType } from '@wpp-open/core/types/tenant'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ProjectViewSettings } from 'api/projects/fetchers/fetchProjectsViewSettingsApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { getAvatarColor } from 'components/common/avatar/utils'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { SvgWrikeLogo } from 'components/svg/WrikeLogo'
import { useHierarchy } from 'hooks/useHierarchy'
import { useWrike } from 'hooks/useWrike'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import { ProjectManageMenu } from 'pages/dashboard/components/projectManageMenu/ProjectManageMenu'
import styles from 'pages/dashboard/components/projectsCardView/components/projectItem/ProjectListItem.module.scss'
import { ProjectStatusIcon } from 'pages/dashboard/components/projectsCardView/components/projectStatus/ProjectStatusIcon'
import { getActivePhases } from 'pages/dashboard/components/projectsTimelineView/utils'
import { ProjectTypeTag } from 'pages/dashboard/components/projectTypeTag/ProjectTypeTag'
import { ProjectAttributeClass } from 'types/projects/attributes'
import { Project, ProjectStatus as Status } from 'types/projects/projects'
import { fullName } from 'utils/common'
import { formatDate } from 'utils/dateFormat'
import { routesManager } from 'utils/routesManager'

interface Props {
  project: Project
  dateLocale?: string
  settings: ProjectViewSettings[]
}

export const ProjectListItem: FC<Props> = ({ project, dateLocale, settings }) => {
  const { t } = useTranslation()
  const { isWrikeAuthenticated } = useWrike()
  const { osContext } = useOs()

  const isMultiTenant = osContext.tenant.tenantType === TenantType.Agency
  const isClientTenant = osContext.tenant.tenantType === TenantType.Client

  const { tenant, contextHierarchy } = project
  const { owners, owner, startDate, endDate, status } = project
  const { sortHierarchy } = useHierarchy()
  const sortedHierarchy = sortHierarchy(contextHierarchy)

  const fromCurrentTenant = osContext.tenant.id === project.tenant?.id

  const contextItems = sortedHierarchy
    ?.filter(hierarchy => (isClientTenant ? hierarchy.title !== DefaultHierarchyLevelType.Client : true))
    .map(hierarchy => hierarchy?.name || '')
    .filter(Boolean)

  const inProgressPhases = useMemo(
    () => getActivePhases(project?.phases ?? []).sort((a, b) => a.orderNumber - b.orderNumber),
    [project],
  )

  const redirectLink = useMemo(() => {
    return `${
      isMultiTenant && !fromCurrentTenant ? `${tenant?.homeUrl}/orchestration` : ''
    }${routesManager.project.workflow.getURL({
      id: project.id,
    })}`
  }, [fromCurrentTenant, isMultiTenant, project.id, tenant?.homeUrl])

  const customSettings = useMemo(
    () => settings.filter(setting => setting.classification === ProjectAttributeClass.CUSTOM && setting.enabled),
    [settings],
  )

  const projectAttributesList = useMemo(
    () =>
      customSettings
        .map(setting => {
          const attr = project?.attributes?.find(attr => attr.name === setting.name)
          const value = Array.isArray(attr?.value) ? attr?.value.join(', ') : attr?.value

          if (!value) return null

          return { name: setting.name, value }
        })
        .filter(Boolean) as { name: string; value: string }[],
    [customSettings, project?.attributes],
  )

  return (
    <WppCard interactive data-testid={`dashboard-project-card-${project.id}`} className={styles.card}>
      <Link to={redirectLink} className={styles.card} target={fromCurrentTenant ? '_self' : '_blank'}>
        <Flex direction="column" className={styles.content} gap={8}>
          {!isMultiTenant && !!contextItems.length && (
            <div className={styles.contextWrapper}>
              <WppTooltip className={styles.contextList} text={contextItems.join('・')}>
                {contextItems.map(item => (
                  <WppTypography type="s-body" className={clsx(styles.contextItem, styles.workspace)} key={item}>
                    {item}
                  </WppTypography>
                ))}
              </WppTooltip>
            </div>
          )}
          <WppTypography type="l-strong" data-testid="project-name" className={styles.projectNameContainer}>
            <ProjectStatusIcon status={status} className={styles.status} />
            <span className={styles.projectName}>{project.name}</span>
          </WppTypography>
          {isMultiTenant && (
            <Flex gap={4} align="center">
              <WppTypography type="s-body" className={styles.projectTenantName}>
                {tenant?.name}
              </WppTypography>
              {!fromCurrentTenant && (
                <WppIconExternalLink height={16} width={16} className={styles.projectTenantNameIcon} />
              )}
            </Flex>
          )}

          {!!inProgressPhases?.length && (
            <div className={styles.phasesWrapper}>
              <WppTypography type="s-body" className={styles.phasesList}>
                {inProgressPhases.map((phase, index) => (
                  <span className={styles.workspace} key={phase.id}>
                    {`${phase.name}${index !== inProgressPhases.length - 1 ? ',' : ''}`}&nbsp;
                  </span>
                ))}
              </WppTypography>
            </div>
          )}

          {!!customSettings.length && fromCurrentTenant && (
            <Flex direction="column" className={styles.attributes}>
              {projectAttributesList.slice(0, 2).map(({ name, value }) => (
                <WppTooltip key={name} text={name}>
                  <Truncate type="s-body" style={{ color: 'var(--wpp-grey-color-800)', maxWidth: '400px' }}>
                    {value}
                  </Truncate>
                </WppTooltip>
              ))}
              {projectAttributesList.length > 2 && (
                <WppTooltip
                  text={projectAttributesList
                    .slice(2)
                    .map(({ name, value }) => `${name}: ${value}`)
                    .join('\n')}
                >
                  <Truncate
                    type="s-body"
                    style={{
                      color: 'var(--wpp-grey-color-800)',
                      textDecoration: 'underline',
                    }}
                  >{`+${projectAttributesList.length - 2} ${t('common.btn_more')}`}</Truncate>
                </WppTooltip>
              )}
            </Flex>
          )}
        </Flex>
        <div className={styles.footer}>
          <WppDivider />
          <Flex direction="row" align="center" gap={12} className={styles.footerContent}>
            {owners?.length === 1 ? (
              <Avatar
                withTooltip
                tooltipConfig={{ placement: 'top' }}
                className={styles.avatar}
                size="xs"
                name={fullName(owner?.firstname, owner?.lastname)}
                src={owner?.avatarUrl ?? ''}
              />
            ) : (
              <WppAvatarGroup
                onClick={e => {
                  e.preventDefault()
                }}
                maxAvatarsToDisplay={4}
                size="xs"
                withTooltip
                users={owners?.map(member => ({
                  name: fullName(member.firstname, member.lastname),
                  src: member.avatarUrl ?? '',
                  color: getAvatarColor({ name: member.firstname }),
                }))}
              />
            )}
            {(startDate || endDate) && (
              <WppTooltip
                text={`${t('common.start_date')}: ${
                  startDate ? formatDate({ dateString: startDate, dateLocale }) : '-'
                } 
                    ${t('common.end_date')}: ${endDate ? formatDate({ dateString: endDate, dateLocale }) : '-'}`}
              >
                <WppIconCalendar />
              </WppTooltip>
            )}

            {project.wrike?.isConnected && <SvgWrikeLogo data-testid="wrike-logo" />}

            <ProjectTypeTag type={project.type} className={styles.projectType} />
          </Flex>
        </div>
      </Link>
      {fromCurrentTenant && (
        <ProjectManageMenu
          project={project}
          securedChildren={
            (status === Status.IN_PROGRESS || status === Status.TO_DO) && (
              <WppListItem
                onWppChangeListItem={() => showProjectEditModal({ project: project })}
                data-testid="edit-action"
                disabled={project.wrike?.isConnected && !isWrikeAuthenticated}
              >
                <WppIconEdit slot="left" />
                <span slot="label">{t('project.list.btn_edit_project')}</span>
              </WppListItem>
            )
          }
        />
      )}
    </WppCard>
  )
}
