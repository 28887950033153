import { CardGroupChangeEventDetail } from '@platform-ui-kit/components-library'
import {
  WppButton,
  WppCard,
  WppCardGroup,
  WppDivider,
  WppEmptyNothingFound,
  WppIconPlus,
  WppInput,
  WppLabel,
  WppSkeleton,
  WppSpinner,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { RefCallback, useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useFetchWorkflowTemplatesInfiniteApi } from 'api/templates/queries/useFetchWorkflowTemplatesInfiniteApi'
import { Flex } from 'components/common/flex/Flex'
import { NoTemplateSvg } from 'components/svg/NoTemplateSvg'
import { useCanCreateProject } from 'hooks/useCanCreateProject'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import styles from 'pages/components/projectModal/components/projectTemplateForm/ProjectTemplateForm.module.scss'
import { TEMPLATE_PREVIEW_CLASS, TemplateCard } from 'pages/components/templateCard/TemplateCard'
import { showTemplatePreview } from 'pages/components/templatePreviewModal/TemplatePreviewModal'
import { SelectProcessType } from 'pages/project/components/canvas/components/selectProcessType/SelectProcessType'
import { showCreateNewTemplateModal } from 'pages/templates/components/createNewTemplateModal/CreateNewTemplateModal'
import { ProcessType, TenantSubset } from 'types/projects/projects'

interface Props {
  processType: ProcessType
}

export const ProjectTemplateForm = ({ processType }: Props) => {
  const {
    osContext: { tenant },
  } = useOs()
  const { t } = useTranslation()
  const { canCreateProjectFromScratch } = useCanCreateProject()
  const { setValue, getValues } = useFormContext()

  const [search, setSearch] = useState<undefined | string>(getValues('templateSearch'))

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  const {
    data: templates,
    isLoading: isTemplatesLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useFetchWorkflowTemplatesInfiniteApi({
    params: {
      processType,
      inputText: search,
      tenantSubset: tenant.tenantType === TenantType.Agency ? TenantSubset.CURRENT : undefined,
    },
  })

  const isFetching = isRefetching || isFetchingNextPage

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage: isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const setSearchDebounced = useDebounceFn((search: string) => {
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setSearch(searchQuery)
    setValue('templateSearch', searchQuery)
  }, 300)

  const handleTemplateSelect = useCallback(
    ({ detail }: CustomEvent<CardGroupChangeEventDetail>) => {
      setValue('templateId', detail.value)
    },
    [setValue],
  )

  // @TODO:  <WppCardGroup key={search} ... /> Remove this part when CL will fix card group (didn't highlight selected cars after content reload)
  return (
    <Flex direction="column" className={styles.container} gap={16}>
      <Flex direction="column" gap={16}>
        <WppLabel
          data-testid="project-process-type-label"
          config={{ text: t('modals.create_project.field_process_type_label') }}
          typography="s-strong"
        />
        <SelectProcessType
          name="processType"
          templateId={getValues().templateId}
          onChange={() => {
            setSearch(undefined)
            setValue('templateSearch', undefined)
          }}
          resetTemplateId={() => {
            setValue('templateId', '')
            setSearch(undefined)
          }}
        />
      </Flex>

      {canCreateProjectFromScratch && (
        <>
          <WppCardGroup value={getValues('templateId')} withRadioOrCheckbox={false} onWppChange={handleTemplateSelect}>
            <WppCard value="">
              <Flex gap={24}>
                <NoTemplateSvg height="75" />
                <Flex direction="column">
                  <WppTypography type="l-strong">{t('modals.create_project.no_template.title')}</WppTypography>
                  <WppTypography type="s-body">{t('modals.create_project.no_template.text')}</WppTypography>
                </Flex>
              </Flex>
            </WppCard>
          </WppCardGroup>

          <WppDivider />
        </>
      )}

      <WppTypography type="l-strong">
        {canCreateProjectFromScratch
          ? t('modals.create_project.select_template')
          : t('modals.create_project.select_template_required')}
      </WppTypography>

      <WppInput
        size="s"
        value={search}
        placeholder={t('modals.create_project.field_search_placeholder')!}
        onWppChange={e => setSearchDebounced(e.detail.value || '')}
        type="search"
        data-testid="create-modal-templates-search"
        className={styles.searchInput}
      />

      <Flex direction="column" className={styles.cardsLoaderContainer}>
        <WppCardGroup
          key={search}
          value={getValues('templateId')}
          withRadioOrCheckbox={false}
          onWppChange={handleTemplateSelect}
          className={styles.cardsGroup}
          data-testid="project-modal-templates"
        >
          <Flex direction="column" gap={16} className={styles.cardsWrapper}>
            {isTemplatesLoading ? (
              <Flex direction="column" gap={24}>
                <WppSkeleton variant="rectangle" height="200px" width="100% " />
                <WppSkeleton variant="rectangle" height="200" width="100% " />
              </Flex>
            ) : templates?.length ? (
              templates.map(template => (
                <div
                  key={template.id}
                  onClickCapture={event => {
                    // we need this handler due to `WppCardGroup` click implementation: it relies on the `capture` phase
                    if (event.target instanceof Element && event.target.classList.contains(TEMPLATE_PREVIEW_CLASS)) {
                      event.stopPropagation()

                      showTemplatePreview({
                        templateId: template.id,
                        handleSubmit: () => setValue('templateId', template?.id),
                      })
                      return
                    }
                  }}
                  data-testid="project-modal-template"
                >
                  <TemplateCard template={template} withPreview />
                </div>
              ))
            ) : (
              <Flex justify="center" direction="column" align="center" gap={24} style={{ height: '100%' }}>
                <WppEmptyNothingFound data-testid="no-template-found" width={120} />
                <Flex gap={8} direction="column" align="center">
                  <WppTypography type="l-strong">
                    {search ? t('modals.create_project.no_results') : t('templates.no_data')}
                  </WppTypography>
                  <WppTypography type="s-body" style={{ textAlign: 'center', maxWidth: '320px' }}>
                    {search ? t('modals.create_project.no_results_description') : t('templates.no_data_description')}
                  </WppTypography>

                  {!search && (
                    <WppButton
                      size="s"
                      onClick={() => showCreateNewTemplateModal({ redirectAfterCreate: false })}
                      data-testid="create-new-template"
                    >
                      <WppIconPlus slot="icon-start" /> {t('modals.create_project.btn_add_template')}
                    </WppButton>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </WppCardGroup>
        <Flex justify="center" ref={setRef} className={styles.nextPageSpinner}>
          {isFetching && <WppSpinner size="m" />}
        </Flex>
      </Flex>
    </Flex>
  )
}
