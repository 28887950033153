// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IMW6q{height:calc(100vh - 180px);padding-bottom:24px;overflow:hidden}.B8YSS{flex-grow:1;overflow-x:auto}.fQo4E{flex-grow:1}.l8LZa{width:100%;padding-bottom:30px}.Ujhez{grid-column:1/-1;padding-bottom:40px}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/projectTemplateForm/ProjectTemplateForm.module.scss"],"names":[],"mappings":"AAAA,OACE,0BAAA,CACA,mBAAA,CACA,eAAA,CAGF,OACE,WAAA,CACA,eAAA,CAGF,OACE,WAAA,CAGF,OACE,UAAA,CACA,mBAAA,CAGF,OACE,gBAAA,CACA,mBAAA","sourcesContent":[".container {\n  height: calc(100vh - 180px);\n  padding-bottom: 24px;\n  overflow: hidden;\n}\n\n.cardsLoaderContainer {\n  flex-grow: 1;\n  overflow-x: auto;\n}\n\n.cardsGroup {\n  flex-grow: 1;\n}\n\n.cardsWrapper {\n  width: 100%;\n  padding-bottom: 30px;\n}\n\n.nextPageSpinner {\n  grid-column: 1/-1;\n  padding-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IMW6q`,
	"cardsLoaderContainer": `B8YSS`,
	"cardsGroup": `fQo4E`,
	"cardsWrapper": `l8LZa`,
	"nextPageSpinner": `Ujhez`
};
export default ___CSS_LOADER_EXPORT___;
