import { WppSkeleton, WppSpinner } from '@platform-ui-kit/components-library-react'
import { matchPath, useLocation } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { BackToProjectsButton } from 'pages/components/backToProjectsButton/BackToProjectsButton'
import { WorkflowSkeleton } from 'pages/components/projectSkeleton/components/workflow/WorkflowSkeleton'
import { useProjectTabs } from 'pages/project/hooks/useProjectTabs'
import styles from 'pages/project/Project.module.scss'
import { routesManager } from 'utils/routesManager'

export const ProjectSkeleton = ({ isCanvasLoading }: { isCanvasLoading: boolean }) => {
  const location = useLocation()
  const tabs = useProjectTabs({ isEditable: true })

  const currentTab =
    matchPath(`${routesManager.project.root.pattern}/:tab`, location.pathname)?.params.tab ||
    routesManager.project.workflow.shortPattern

  return (
    <Flex direction="column" className={styles.container}>
      <BackToProjectsButton />
      <Flex gap={16} direction="column">
        <Flex>
          <WppSkeleton variant="rectangle" height="40px" width="100%" />
        </Flex>

        <Flex gap={24}>
          {tabs.map(({ id }) => (
            <WppSkeleton variant="rectangle" height="34px" width="115px" key={id} />
          ))}
        </Flex>

        {getSkeleton(currentTab, isCanvasLoading)}
      </Flex>
    </Flex>
  )
}

const getSkeleton = (tab: string, isCanvasLoading: boolean) => {
  switch (tab) {
    case routesManager.project.workflow.shortPattern:
      return <WorkflowSkeleton isCanvasLoading={isCanvasLoading} />
  }

  return (
    <Flex justify="center" align="center" className={styles.spinner}>
      <WppSpinner size="l" />
    </Flex>
  )
}
