export enum LocalStorageKey {
  // these two "old" keys are needed only for LS cleanup, and can be safely removed after several releases
  DASHBOARD_FILTERS_OLD = 'ds',
  DASHBOARD_TASKS_FILTERS_OLD = 'dstasks',
  DASHBOARD_FILTERS = 'ds_lazy',
  DASHBOARD_TASKS_FILTERS = 'dstasks_lazy',
  PROJECT_FILES_FILTERS = 'files',
  PROJECT_TASKS_FILTERS = 'tasks',
  PROJECT_TIMELINE_FILTERS = 'timeline',
  TEMPLATE_TASKS_FILTERS = 'ttasks',
}
