import {
  WppActionButton,
  WppButton,
  WppCard,
  WppIconClock,
  WppIconEdit,
  WppIconMore,
  WppIconPlus,
  WppIconSuccess,
  WppIconTrash,
  WppIconWarning,
  WppListItem,
  WppMenuContext,
  WppPill,
  WppTag,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { SelectionChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, Table } from 'components/common/table'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { Truncate } from 'components/common/truncate/Truncate'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { showInviteMembersModal } from 'pages/project/components/members/components/inviteMembersModal/InviteMembersModal'
import { showMemberModal } from 'pages/project/components/members/components/memberModal/MemberModal'
import { showRemoveMembersModal } from 'pages/project/components/members/components/removeMembersModal/RemoveMembersModal'
import styles from 'pages/project/components/members/Members.module.scss'
import { nameSortComparator, stringSortComparator } from 'pages/project/components/members/utils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { Members, WrikeIntegrationStatus } from 'types/members/members'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProjectStatus } from 'types/projects/projects'
import { capitalizeFirstLetter, join } from 'utils/common'

import { useMembersAnalytics } from '../../../../hooks/useMembersAnalytics'

const statusMapping = {
  CONNECTED: {
    icon: <WppIconSuccess size="s" />,
    textKey: 'project.members.wrike_status.CONNECTED',
  },
  NOT_CONNECTED: {
    icon: <WppIconWarning size="s" />,
    textKey: 'project.members.wrike_status.NOT_CONNECTED',
  },
  INVITATION_PENDING: {
    icon: <WppIconClock size="s" className={styles.grey800} />,
    textKey: 'project.members.wrike_status.INVITATION_PENDING',
  },
  NO_ACCOUNT: {
    icon: null,
    textKey: 'project.members.wrike_status.NO_ACCOUNT',
  },
}

const WrikeStatus = ({ status }: { status: WrikeIntegrationStatus }) => {
  const { t } = useTranslation()

  const effectiveStatus = status || 'NO_ACCOUNT'
  const { icon, textKey } = statusMapping[effectiveStatus]

  return (
    <Flex direction="row" gap={4} align="center">
      {icon}
      <WppTypography type="s-body" className={styles[effectiveStatus]} data-testid="table-wrike-status">
        {t(textKey)}
      </WppTypography>
    </Flex>
  )
}

export const MembersTab = () => {
  const gridRef = useRef<AgGridReact<Members>>(null)
  const { t } = useTranslation()

  const { project, members, isMembersLoading, isUserConnectWrike } = useProject()
  const [selectedMembers, setSelectedMembers] = useState<Members[]>([])
  const { trackViewProjectMembers, trackProjectMemberRemoved } = useMembersAnalytics({ project })

  const { isPermitted } = useIsPermitted()
  const { hasRole } = useHasProjectRole()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const ownersSelected = useMemo(
    () => selectedMembers.filter(member => member.role === ProjectRole.OWNER),
    [selectedMembers],
  )
  const allOwners = useMemo(() => members.filter(member => member.role === ProjectRole.OWNER), [members])

  const userNotConnectedToWrike = isUserConnectWrike === null ? false : !isUserConnectWrike

  const isProjectDone = [ProjectStatus.COMPLETED, ProjectStatus.ARCHIVED].includes(project.status)

  useEffect(() => {
    if (gridRef.current) setSelectedMembers(gridRef.current?.api?.getSelectedRows() || [])
  }, [members])

  useEffect(() => {
    trackViewProjectMembers()
  }, [trackViewProjectMembers])

  const openMemberModal = useCallback(
    ({ member: selectedMember, allOwnersSelected }: { member: Members; allOwnersSelected: boolean }) => {
      showMemberModal({ member: selectedMember, project, allOwnersSelected })
    },
    [project],
  )

  const openDeleteMemberModal = useCallback(
    (member: Members[]) => {
      showRemoveMembersModal({
        membersToDelete: member,
        projectId: project.id,
        onDelete: () => trackProjectMemberRemoved(member.length),
      })
    },
    [project.id, trackProjectMemberRemoved],
  )

  const isWrikeExternalSelectedFn = useCallback((members: Members[]) => {
    return members.some(member => member.role === ProjectRole.EXTERNAL)
  }, [])

  /**
   * Cases:
   *  When we select the all OWNERS AND external user
   *  When we select the all OWNERS
   *  When we select the all OWNERS but only one OWNER
   *  When we select external user
   *  When PROJECT is done
   *  When project is connect to wrike, but user is not authenticated
   */
  const getRemoveAllTooltip = useCallback(
    (isWrikeExternalSelected: boolean) => {
      if (userNotConnectedToWrike || isProjectDone) {
        return t('project.members.cant_remove')!
      }

      if (isWrikeExternalSelected && ownersSelected.length === allOwners.length) {
        return t('project.members.cant_remove_external_and_all_owners')!
      }

      if (isWrikeExternalSelected) {
        return t('project.members.cant_remove_external')!
      }

      if (allOwners.length === 1 && ownersSelected.length === 1) {
        return t('project.members.cant_remove_owner')!
      }

      if (ownersSelected.length === allOwners.length) {
        return t('project.members.cant_remove_all_owners')!
      }

      return null
    },
    [allOwners.length, isProjectDone, ownersSelected.length, t, userNotConnectedToWrike],
  )

  /**
   * Cases:
   *  When we select the only OWNER
   *  When we select external user
   *  When PROJECT is done
   *  When project is connect to wrike, but user is not authenticated
   */
  const getRemoveCellTooltip = useCallback(
    (isWrikeExternalSelected: boolean, role: ProjectRole) => {
      const isOwnersCell = role === ProjectRole.OWNER

      if (userNotConnectedToWrike || isProjectDone) {
        return t('project.members.cant_remove')!
      }

      if (isWrikeExternalSelected) {
        return t('project.members.cant_remove_external')!
      }

      if (allOwners.length === 1 && isOwnersCell) {
        return t('project.members.cant_remove_owner')!
      }

      return null
    },
    [allOwners.length, isProjectDone, t, userNotConnectedToWrike],
  )

  const columnDefs = useMemo<ColDef<Members>[]>(
    () => [
      {
        colId: 'name',
        field: 'lastname',
        sortable: true,
        comparator: nameSortComparator,
        flex: 2.5,
        minWidth: 250,
        cellClass: 'user-name-cell',
        headerCheckboxSelection: isOwnerOrGlobalManage && !userNotConnectedToWrike,
        checkboxSelection: isOwnerOrGlobalManage && !userNotConnectedToWrike,
        headerName: t('project.members.table.name'),
        cellRenderer: ({ data }) => {
          const { firstname, lastname, email, avatarUrl, isExternal } = data!
          const name = join([firstname || data?.wrikeExternalFirstName, lastname || data?.wrikeExternalLastName], ' ')
          const isWrike = data?.wrikeIntegrationStatus === WrikeIntegrationStatus.NO_ACCOUNT

          return (
            <Flex gap={16} align="center" className={styles.overflow}>
              <Flex align="center">
                <Avatar size="s" name={name || email} src={avatarUrl ?? ''} />
              </Flex>

              <Flex justify="between" align="center" style={{ width: '100%' }}>
                <Flex direction="column" className={styles.ellipsis}>
                  <Truncate type="s-body" data-testid="table-username" lines={1}>
                    {name}
                  </Truncate>
                  <Truncate type="xs-body" data-testid="table-email" lines={1}>
                    {email || data?.wrikeEmail}
                  </Truncate>
                </Flex>
                {(isWrike || isExternal) && (
                  <WppTag
                    label={t(isWrike ? 'project.members.external_wrike' : 'project.members.external')}
                    variant="neutral"
                  />
                )}
              </Flex>
            </Flex>
          )
        },
      },
      {
        colId: 'agency',
        field: 'agency',
        sortable: true,
        comparator: stringSortComparator,
        flex: 1.5,
        minWidth: 150,

        headerName: t('project.members.table.organization'),
        cellRenderer: ({ data }) => (
          <WppTypography className={styles.overflow} type="s-body">
            {data?.agency || '-'}
          </WppTypography>
        ),
      },
      {
        colId: 'jobTitle',
        field: 'jobTitle',
        sortable: true,
        comparator: stringSortComparator,
        flex: 1.5,
        minWidth: 150,

        headerName: t('project.members.table.job_title'),
        cellRenderer: ({ data }) => (
          <WppTypography type="s-body" className={styles.overflow}>
            {data?.jobTitle || '-'}
          </WppTypography>
        ),
      },
      {
        colId: 'access',
        field: 'role',
        sortable: true,
        headerName: t('project.members.table.access'),
        flex: 1.5,
        minWidth: 150,
        cellRenderer: ({ data }) => {
          return (
            <WppTag
              label={capitalizeFirstLetter(data?.role?.toLowerCase())}
              className={styles[data?.role || ProjectRole.VIEWER]}
              variant="neutral"
            />
          )
        },
      },
      {
        colId: 'wrike_account',
        field: 'wrikeIntegrationStatus',
        sortable: true,
        headerName: t('project.members.table.wrike_account'),
        flex: 1.5,
        minWidth: 150,
        hide: !project.wrike?.isConnected,
        cellRenderer: ({ data }) => {
          return <WrikeStatus status={data?.wrikeIntegrationStatus!} />
        },
      },
      isOwnerOrGlobalManage
        ? {
            width: 64,
            minWidth: 64,
            resizable: false,
            pinned: 'right',
            colId: 'actions',
            cellRenderer: ({ data }) => (
              <WppMenuContext
                className={styles.rowContextMenu}
                dropdownConfig={{
                  appendTo: () => document.body,
                  placement: 'bottom-end',
                }}
              >
                <WppActionButton slot="trigger-element" variant="secondary">
                  <WppIconMore direction="horizontal" slot="icon-start" />
                </WppActionButton>
                <Flex direction="column" gap={4}>
                  <WppListItem
                    onWppChangeListItem={() =>
                      openMemberModal({
                        member: data!,
                        allOwnersSelected: allOwners.length === 1 && data?.role === ProjectRole.OWNER,
                      })
                    }
                    data-testid="edit-group-action"
                  >
                    <WppIconEdit slot="left" />
                    <WppTypography slot="label" type="s-body">
                      {t('common.btn_edit')}
                    </WppTypography>
                  </WppListItem>
                  {getRemoveCellTooltip(isWrikeExternalSelectedFn([data!]), data?.role!) ? (
                    <WppTooltip
                      config={{ placement: 'left' }}
                      text={getRemoveCellTooltip(isWrikeExternalSelectedFn([data!]), data?.role!)!}
                    >
                      <WppListItem data-testid="delete-group-context-action" disabled={true}>
                        <WppIconTrash slot="left" />
                        <span slot="label">{t('common.btn_remove')}</span>
                      </WppListItem>
                    </WppTooltip>
                  ) : (
                    <WppListItem
                      onWppChangeListItem={() => openDeleteMemberModal([data!])}
                      data-testid="delete-group-context-action"
                    >
                      <WppIconTrash slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('common.btn_remove')}
                      </WppTypography>
                    </WppListItem>
                  )}
                </Flex>
              </WppMenuContext>
            ),
            maxWidth: 60,
          }
        : {},
    ],
    [
      isOwnerOrGlobalManage,
      userNotConnectedToWrike,
      t,
      project.wrike,
      allOwners.length,
      isWrikeExternalSelectedFn,
      getRemoveCellTooltip,
      openMemberModal,
      openDeleteMemberModal,
    ],
  )

  const handleSelectionChanged = ({ api }: SelectionChangedEvent) => setSelectedMembers(api.getSelectedRows())

  return (
    <>
      <PageBackToTop />
      <WppCard className={styles.container}>
        <Flex direction="column" gap={29} className={styles.tableWrapper}>
          <Flex justify="end">
            {isOwnerOrGlobalManage && !userNotConnectedToWrike && (
              <Flex gap={20}>
                {!!selectedMembers.length ? (
                  <>
                    <WppPill
                      removable={true}
                      type="display"
                      label={`${selectedMembers.length} selected`}
                      name="chip"
                      onWppClose={() => gridRef.current?.api.deselectAll()}
                    />

                    {getRemoveAllTooltip(isWrikeExternalSelectedFn(selectedMembers!)) ? (
                      <WppTooltip text={getRemoveAllTooltip(isWrikeExternalSelectedFn(selectedMembers!))!}>
                        <WppActionButton variant="destructive" data-testid="delete-group-card-action" disabled={true}>
                          <WppIconTrash slot="icon-start" />
                          {t('common.btn_remove')}
                        </WppActionButton>
                      </WppTooltip>
                    ) : (
                      <WppActionButton
                        variant="destructive"
                        data-testid="delete-group-card-action"
                        onClick={() => openDeleteMemberModal(selectedMembers)}
                      >
                        <WppIconTrash slot="icon-start" />
                        {t('common.btn_remove')}
                      </WppActionButton>
                    )}
                  </>
                ) : (
                  <WppButton
                    size="s"
                    onClick={() => showInviteMembersModal({ actualMembers: members })}
                    disabled={isMembersLoading}
                  >
                    <WppIconPlus slot="icon-start" />
                    {t('project.members.invite_members')}
                  </WppButton>
                )}
              </Flex>
            )}
          </Flex>

          <Table
            className={styles.table}
            ref={gridRef}
            rowModelType="clientSide"
            domLayout="autoHeight"
            rowData={members}
            cacheBlockSize={50}
            rowHeight={60}
            columnDefs={columnDefs}
            rowSelection="multiple"
            suppressPaginationPanel
            suppressRowClickSelection
            onSelectionChanged={handleSelectionChanged}
          />
        </Flex>
      </WppCard>
    </>
  )
}
