import { NavigationTreeMapping } from '@wpp-open/core'
import { HierarchyContainerNodeId, HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { HierarchyTreeNode } from 'api/hubs/fetchers/utils'
import { useFetchTenantHierarchy } from 'api/tenant/queries/useFetchNavigationTree'

const getNodeType = (node: HierarchyTreeNode) =>
  (node?.type === HierarchyCustomNodeType ? node.customTypeName : node?.type)?.toLocaleLowerCase() as string

const LOOP_LIMIT = 10
interface Params {
  clientMapping: NavigationTreeMapping
  tenantId: string
  enabled: boolean
}

export const usePreselectedHierarchy = ({ clientMapping, tenantId, enabled: preselectionEnabled }: Params) => {
  const iterationRef = useRef(0)

  const firstLevelNodes = useMemo(() => {
    return (
      clientMapping[HierarchyContainerNodeId].children
        .map(key => clientMapping[key])
        // @TODO: remove this after fix core hierarchy
        .filter(node => node.type === 'CLIENT')
    )
  }, [clientMapping])

  const defaults = useMemo(() => {
    // handle first level
    if (preselectionEnabled && firstLevelNodes.length === 1) {
      const firstLevelNode = firstLevelNodes[0] as HierarchyTreeNode
      const loadNextLevel = firstLevelNode.children.length === 1

      return {
        hierarchy: {
          [getNodeType(firstLevelNode)]: firstLevelNode.azId!,
        },
        isLoading: loadNextLevel,
        parentId: loadNextLevel ? firstLevelNode.azId! : null,
      }
    }

    return {
      parentId: null,
      isLoading: false,
      hierarchy: {} as Record<string, string>,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [parentId, setParentId] = useState<string | null>(defaults.parentId)
  const [result, setResult] = useState({
    hierarchy: defaults.hierarchy,
    isLoading: defaults.isLoading,
  })

  // load next level when parentId changed and not empty
  const {
    data: nextLevelData,
    isLoading,
    isError,
  } = useFetchTenantHierarchy({
    params: { tenantId: tenantId, parentId: parentId! },
    enabled: !!parentId,
  })

  const completeResults = useCallback(() => {
    setResult(prev => ({
      ...prev,
      isLoading: false,
    }))
    setParentId(null)
  }, [])

  useEffect(() => {
    if (isError) {
      console.warn('preselection hierarchy failed with loading error')
      completeResults()
    }
  }, [completeResults, isError])

  // handle next loaded level
  useEffect(() => {
    if (isLoading || !nextLevelData?.mapping) {
      return
    }

    iterationRef.current++
    if (iterationRef.current > LOOP_LIMIT) {
      completeResults()
      return
    }

    let nextLevelList = Object.values(nextLevelData?.mapping || {})
    if (nextLevelList.length === 1) {
      const nextLevelNode = nextLevelList[0] as HierarchyTreeNode
      const loadNewLevel = nextLevelNode.children?.length === 1

      setResult(prev => ({
        hierarchy: {
          ...prev.hierarchy,
          [getNodeType(nextLevelNode)]: nextLevelNode.azId!,
        },
        isLoading: loadNewLevel,
      }))

      setParentId(loadNewLevel ? nextLevelNode.azId! : null)
      return
    }

    // amount of nodes !== 1, no more preselection
    completeResults()
  }, [completeResults, isLoading, nextLevelData])

  return result
}
