import { WppInlineMessage, WppLabel, WppTypography } from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ProjectTypesDTO } from 'api/projects/fetchers/fetchProjectTypes'
import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { useHierarchy } from 'hooks/useHierarchy'
import { DateRangeAttribute } from 'pages/components/projectModal/components/attributes/DateRangeAttribute'
import { MultiSelectAttribute } from 'pages/components/projectModal/components/attributes/MultiSelectAttribute'
import { SingleSelectAttribute } from 'pages/components/projectModal/components/attributes/SingleSelectAttribute'
import { TextAreaAttribute } from 'pages/components/projectModal/components/attributes/TextAreaAttribute'
import { TextInputAttribute } from 'pages/components/projectModal/components/attributes/TextInputAttribute'
import { HierarchySelect } from 'pages/components/projectModal/components/hierarchySelect/HierarchySelect'
import styles from 'pages/components/projectModal/components/projectForm/ProjectForm.module.scss'
import { WorkspaceWarning } from 'pages/components/workspaceWarning/WorkspaceWarning'
import { SelectProcessType } from 'pages/project/components/canvas/components/selectProcessType/SelectProcessType'
import { ProjectAttribute, ProjectAttributeType } from 'types/projects/attributes'
import { ContextHierarchy } from 'types/projects/projects'
import { capitalizeFirstLetter, COMMON_VALIDATION } from 'utils/common'

export const ProjectForm = ({
  showProcessType = false,
  contextHierarchy = [],
  showWarning = false,
  systemAttributes,
  customAttributes,
  projectTypes,
}: {
  showProcessType?: boolean
  showWarning?: boolean
  contextHierarchy?: ContextHierarchy[]
  systemAttributes?: ProjectAttribute[]
  customAttributes?: ProjectAttribute[]
  projectTypes?: ProjectTypesDTO[]
}) => {
  const { t } = useTranslation()
  const { osContext } = useOs()

  const { hierarchyOrder } = useHierarchy()
  const isAgencyWorkspace = osContext.tenant.tenantType === TenantType.Agency

  const {
    contextWorkspace: attributeContext,
    name: attributeName,
    description: attributeDescription,
    type: attributeType,
    processType: attributeProcessType,
    startDate_endDate: attributeDueDate,
    ...systemAttributesMap
  } = useMemo(
    () => Object.fromEntries(systemAttributes?.map(attribute => [attribute.contractName, attribute]) ?? []),
    [systemAttributes],
  )

  const statusOptions = useMemo(() => {
    if (attributeType) {
      return attributeType.valueOptions?.map(option => ({
        value: option,
        label: capitalizeFirstLetter(option.replace('_', ' ').toLowerCase()),
      }))
    }

    // default options
    return projectTypes?.map(({ name }) => ({
      value: name,
      label: t(`modals.create_project.project_type_${name.toLowerCase()}`, {
        defaultValue: capitalizeFirstLetter(name.toLowerCase()),
      }),
    }))
  }, [attributeType, projectTypes, t])

  return (
    <Flex direction="column" gap={69} className={styles.paddingBottom}>
      <Flex direction="column" gap={24}>
        <Flex direction="column" gap={4}>
          <FormInput
            name="name"
            data-testid="modal-project-name"
            labelConfig={{ text: t('modals.create_project.field_name_label') }}
            placeholder={t('modals.create_project.field_name_placeholder')!}
            required={attributeName?.required ?? true}
            disabled={attributeName?.enabled === false}
          />

          {isAgencyWorkspace && (
            <WppTypography type="xs-strong" className={styles.greyColor800}>
              {t('modals.create_project.workspace_description', { name: osContext.tenant.name })}
            </WppTypography>
          )}
        </Flex>

        <FormSelect
          name="type"
          type="single"
          data-testid="project-type-select"
          options={statusOptions!}
          labelConfig={{ text: t('modals.create_project.field_type_label') }}
          required={attributeType?.required ?? true}
          disabled={attributeType?.enabled === false}
        />

        {showProcessType && (
          <Flex direction="column" gap={8}>
            <WppLabel
              data-testid="project-process-type-label"
              config={{ text: t('modals.create_project.field_process_type_label') }}
              typography="s-strong"
            />
            <SelectProcessType
              name="processType"
              required={attributeProcessType?.required ?? true}
              disabled={attributeProcessType?.enabled === false}
            />
          </Flex>
        )}

        <Flex direction="column" gap={12}>
          {showWarning && (
            <WppInlineMessage
              size="m"
              message={t('project.about.incorrect_workspace')}
              type="warning"
              showTooltipFrom={100}
            />
          )}
          <WorkspaceWarning contextHierarchy={contextHierarchy} />
          <div className={styles.grid}>
            {hierarchyOrder.map((hierarchy, index) => (
              <HierarchySelect key={hierarchy} index={index} hierarchy={hierarchy} />
            ))}
            {hierarchyOrder.length % 2 !== 0 && <div />}
            <FormDatepicker
              className={styles.datePicker}
              name="dueDate"
              placeholder={t('modals.create_project.field_due_date_placeholder')}
              labelConfig={{ text: t('modals.create_project.field_due_date_label') }}
              range
              required={attributeDueDate?.required ?? true}
              disabled={attributeDueDate?.enabled === false}
            />
          </div>
        </Flex>
        <FormTextareaInput
          name="description"
          data-testid="project-description"
          labelConfig={{ text: t('modals.create_project.field_description_label') }}
          placeholder={t('modals.create_project.field_description_placeholder')!}
          warningThreshold={COMMON_VALIDATION.description.warning}
          charactersLimit={COMMON_VALIDATION.description.max}
          required={attributeDescription?.required ?? true}
          disabled={attributeDescription?.enabled === false}
        />

        {Object.values(systemAttributesMap).map(attribute => {
          switch (attribute.type) {
            case ProjectAttributeType.TEXT_INPUT:
              return <TextInputAttribute key={attribute.id} attribute={attribute} />
            case ProjectAttributeType.TEXT_AREA:
              return <TextAreaAttribute key={attribute.id} attribute={attribute} />
            case ProjectAttributeType.DATE_RANGE:
              return <DateRangeAttribute key={attribute.id} attribute={attribute} />
            case ProjectAttributeType.SINGLE_SELECT:
              return <SingleSelectAttribute key={attribute.id} attribute={attribute} />
            case ProjectAttributeType.MULTI_SELECT:
              return <MultiSelectAttribute key={attribute.id} attribute={attribute} />
            default:
              return null
          }
        })}

        {customAttributes?.map(attribute => {
          switch (attribute.type) {
            case ProjectAttributeType.TEXT_INPUT:
              return <TextInputAttribute key={attribute.id} attribute={attribute} formGroup="customAttributes" />
            case ProjectAttributeType.TEXT_AREA:
              return <TextAreaAttribute key={attribute.id} attribute={attribute} formGroup="customAttributes" />
            case ProjectAttributeType.DATE_RANGE:
              return <DateRangeAttribute key={attribute.id} attribute={attribute} formGroup="customAttributes" />
            case ProjectAttributeType.SINGLE_SELECT:
              return <SingleSelectAttribute key={attribute.id} attribute={attribute} formGroup="customAttributes" />
            case ProjectAttributeType.MULTI_SELECT:
              return <MultiSelectAttribute key={attribute.id} attribute={attribute} formGroup="customAttributes" />
            default:
              return null
          }
        })}
      </Flex>
    </Flex>
  )
}
