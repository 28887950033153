import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useAssignMember } from 'hooks/useAssignMember'
import { useProject } from 'hooks/useProject'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import styles from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AppActivityItem.module.scss'
import { ActivityItem } from 'types/projects/workflow'
import { isTaskStatusResolved } from 'utils/project'

interface Props {
  activity: ActivityItem
}

export const ActivityDetailsModalHeader = ({ activity }: Props) => {
  const { useExternalStatuses } = useProject() ?? {}
  const assignMember = useAssignMember(activity?.assignUser)
  const { t } = useTranslation()
  const isResolved = !!activity.task && isTaskStatusResolved(activity.task)

  return (
    <Flex direction="column" gap={10}>
      <WppTypography type="l-strong" data-testid="activity-name">
        {activity.name}
      </WppTypography>
      <Flex direction="column" gap={12}>
        <Flex align="center" className={styles.infoBlock}>
          <WppTypography type="s-strong" className={styles.title}>
            {t('modals.phase_details.status')}
          </WppTypography>
          <WppTypography type="s-strong" className={styles.greyColor900} data-testid="app-task-status">
            {useExternalStatuses
              ? activity.task?.wrike?.externalStatus!
              : t(`project.tasks.status.${activity.task.status}`)!}
          </WppTypography>
        </Flex>
        <Flex align="center" className={styles.infoBlock}>
          <WppTypography type="s-strong" className={styles.title}>
            {t('modals.phase_details.assignee')}
          </WppTypography>
          {!assignMember ? (
            <WppTypography type="s-strong" className={styles.greyColor500}>
              {t('modals.phase_details.no_assignee')}
            </WppTypography>
          ) : (
            <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="application-item-assignee" showName />
          )}
        </Flex>
        <Flex align="center" className={styles.infoBlock}>
          <WppTypography type="s-strong" className={styles.title}>
            {t('modals.phase_details.due_date')}
          </WppTypography>
          {activity?.startDate || activity?.endDate ? (
            <Calendar
              startDate={activity?.startDate}
              endDate={activity.endDate}
              data-testid="application-item-dates"
              resolved={isResolved}
            />
          ) : (
            <WppTypography type="s-strong" className={styles.greyColor500}>
              {t('modals.phase_details.no_due_date')}
            </WppTypography>
          )}
        </Flex>

        {activity.description && (
          <Flex direction="column">
            <WppTypography type="s-strong" className={styles.title}>
              {t('modals.phase_details.description')}
            </WppTypography>
            <Truncate
              lines={3}
              type="s-body"
              className={styles.greyColor1000}
              data-testid="description"
              title={activity.description}
            >
              {activity.description}
            </Truncate>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
