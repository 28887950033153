import { NavigationTree } from '@wpp-open/core'

import { facadeApi } from 'api'

interface Params {
  tenantId: string
  parentId?: string
  includeApps?: boolean
}

export const fetchNavigationTree = ({ tenantId, parentId }: Params) =>
  facadeApi.get<NavigationTree>(`/v3/tenants/${tenantId}/navigation-tree`, { params: { parentId } })
