import {
  WppTypography,
  WppIconTableSort,
  WppIconTableSortAsc,
  WppIconTableSortDesc,
} from '@platform-ui-kit/components-library-react'
import { IHeaderParams, SortDirection } from 'ag-grid-community'
import { useEffect, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'

export const DefaultColumnHeader = ({ enableSorting, column, setSort, displayName }: IHeaderParams) => {
  const [sortDirection, setSortDirection] = useState<SortDirection>(null)

  const handleSortChange = () => {
    const sort = column.getSort() ?? null
    const sortingOrder = column.getColDef().sortingOrder!
    const sortIndex = sortingOrder.indexOf(sort)
    const nextSortIndex = sortIndex === sortingOrder.length - 1 ? 0 : sortIndex + 1

    setSort(sortingOrder[nextSortIndex])
  }

  useEffect(() => {
    const sortHandler = () => {
      const sort = column.getSort()
      setSortDirection(sort ?? null)
    }
    column.addEventListener('sortChanged', sortHandler)

    return () => {
      column.removeEventListener('sortChanged', sortHandler)
    }
  }, [column])

  const SortIcon =
    sortDirection === 'asc' ? WppIconTableSortAsc : sortDirection === 'desc' ? WppIconTableSortDesc : WppIconTableSort
  const testId = `sort-icon-${column.getColId()}-${sortDirection ?? 'none'}`

  return (
    <Flex
      className="ag-header-cell-comp"
      {...(enableSorting && {
        onClick: handleSortChange,
      })}
    >
      <WppTypography type="s-strong">{displayName}</WppTypography>
      {enableSorting && <SortIcon className="ag-sorting" data-testid={testId} />}
    </Flex>
  )
}
