import { SelectChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppSelectCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppActionButton,
  WppButton,
  WppFilterButton,
  WppIconBarHorizontal,
  WppIconDataViewCards,
  WppIconDataViewList,
  WppIconEye,
  WppIconPlus,
  WppInput,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppSpinner,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { ViewMode } from 'gantt-task-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { useSessionStorage } from 'react-use'

import { useFetchProjectsViewSettingsApi } from 'api/projects/queries/useFetchProjectsViewSettingsApi'
import { useProjectAttributesApi } from 'api/projects/queries/useProjectAttributesApi'
import { Flex } from 'components/common/flex/Flex'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { ACTION_ANALYTICS } from 'constants/analytics'
import { useTrackAction } from 'hooks/useAnalytics'
import { useCanCreateProject } from 'hooks/useCanCreateProject'
import { useDebounceFn } from 'hooks/useDebounceFn'
import useEventSource from 'hooks/useEventSource'
import { useFilters } from 'hooks/useFilters'
import { useHierarchy } from 'hooks/useHierarchy'
import { showCreateProjectModal } from 'pages/components/projectModal/CreateProjectModal'
import { DashboardNavigation } from 'pages/dashboard/components/dashboardNavigation/DashboardNavigation'
import { OnRouteEnter } from 'pages/dashboard/components/onRouteEnter/OnRouteEnter'
import { showProjectFilterModal } from 'pages/dashboard/components/projectFilterModal/ProjectFilterModal'
import { ProjectCardView } from 'pages/dashboard/components/projectsCardView/ProjectCardView'
import { ProjectsTableView } from 'pages/dashboard/components/projectsTableView/ProjectsTableView'
import { ProjectsTimelineView } from 'pages/dashboard/components/projectsTimelineView/ProjectsTimelineView'
import { showProjectsViewSettingsModal } from 'pages/dashboard/components/projectsViewSettingsModal/ProjectsViewSettingsModal'
import { TenantFilterSelect } from 'pages/dashboard/components/tenantFilterSelect/TenantFilterSelect'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { useIsFiltersActive } from 'pages/dashboard/utils/useIsFiltersActive'
import { ViewType } from 'pages/dashboard/utils/useSessionViewType'
import { LocalStorageKey } from 'types/common/localStorage'
import { ProjectAttribute, ProjectAttributeClass, ProjectAttributeType } from 'types/projects/attributes'
import { ProjectFilter, ProjectOwnership, ProjectStatus } from 'types/projects/projects'
import { getHierarchyFilterCounter } from 'utils/filters'
import { routesManager } from 'utils/routesManager'

const filtersCount = ({
  status,
  type,
  ownership,
  search,
  workspace,
  tenant,
  viewMode,
  attributes,
  includeEmptyWorkspace,
  ...hierarchy
}: ProjectFilter) => {
  const hierarchyCount = getHierarchyFilterCounter(hierarchy)
  const restCount = Object.values({ ...attributes }).reduce((acc, curr) => acc + curr?.length, 0)

  return (status as any[]).concat(type, ownership).length + restCount + hierarchyCount
}

export const DashboardSkeleton = () => (
  <Flex justify="center" align="center" className={styles.spinner}>
    <WppSpinner size="l" />
  </Flex>
)

export const initialProjectFilters: ProjectFilter = {
  status: [ProjectStatus.TO_DO, ProjectStatus.IN_PROGRESS],
  tenant: [],
  type: [],
  search: undefined,
  ownership: ProjectOwnership.ALL,
  viewMode: ViewMode.Day,
  includeEmptyWorkspace: false,
}

const CACHE_TIME = 10 * 60 * 1000

export const DashboardPage = () => {
  const { isLoading: isFiltersLoading, state: filtersState } = useFilters({
    initState: initialProjectFilters,
    lsCode: LocalStorageKey.DASHBOARD_FILTERS,
  })

  const { data: attributes, isLoading: isAttributesLoading } = useProjectAttributesApi({ staleTime: CACHE_TIME })

  if (isFiltersLoading || isAttributesLoading)
    return (
      <Flex justify="center" align="center" className={styles.spinner}>
        <WppSpinner size="l" />
      </Flex>
    )

  return <Dashboard filtersState={filtersState} attributes={attributes} />
}

const Dashboard = ({
  filtersState,
  attributes = [],
}: {
  filtersState?: ProjectFilter
  attributes?: ProjectAttribute[]
}) => {
  const { t } = useTranslation()
  const {
    osContext: { userDetails, tenant },
  } = useOs()
  const navigate = useNavigate()

  const { data: settings } = useFetchProjectsViewSettingsApi()

  const { trackAction } = useTrackAction()
  const { canCreateProject } = useCanCreateProject()
  const eventSource = useEventSource()

  const [projectsExist, setProjectsExist] = useState<boolean>(false)
  const [, setHideSubHeader] = useState(false)

  const [viewType, setViewType] = useSessionStorage('WPP_OPEN_PROJECTS_VIEW', ViewType.CARDS)

  const customSelectAttributes = attributes
    .filter(
      attribute =>
        attribute.classification === ProjectAttributeClass.CUSTOM &&
        attribute.enabled &&
        [ProjectAttributeType.MULTI_SELECT, ProjectAttributeType.SINGLE_SELECT].includes(attribute.type),
    )
    .sort((a, b) => a.orderNumber! - b.orderNumber!)

  const { hierarchyOrder } = useHierarchy()

  const [filter, setFilters] = useState<ProjectFilter>({
    ...initialProjectFilters,
    ...hierarchyOrder.reduce(
      (pre, curr) => ({
        ...pre,
        [curr]: '',
      }),
      {},
    ),
    attributes: customSelectAttributes?.reduce((acc, curr) => ({ ...acc, [curr.contractName]: [] }), {}),
    ...filtersState,
  })

  useEffect(() => {
    const { search, ...rest } = filter
    localStorage.setItem(`${LocalStorageKey.DASHBOARD_FILTERS}:${userDetails.id}`, JSON.stringify(rest))
  }, [filter, userDetails.id])

  const filtersCounts = useMemo(() => filtersCount(filter), [filter])

  const isFilterActive = useIsFiltersActive(filter)

  useEffect(() => {
    if (eventSource === routesManager.projects.root.shortPattern) return
    const analyticsData = {
      workspace_name: tenant.name,
      workspace_type: tenant.tenantType,
      user_agency: userDetails?.agency,
      event_source: eventSource,
      //default filter counter is 3
      filters_applied: filtersCounts > 3,
      search_appied: !!filter.search,
    }

    trackAction(ACTION_ANALYTICS.ACTION_PROJECTS_VIEW, analyticsData)
    // exclude other data, as we should track only event source and filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackAction, filter.search, eventSource, filtersCounts])

  const setSearchDebounced = useDebounceFn((search: string) => {
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setFilters(filters => ({ ...filters, search: searchQuery }))
  }, 300)

  const handleCloseFilterModal = (newFilter: ProjectFilter) => {
    setFilters({ ...filter, ...newFilter })
  }

  const handleWorkspaceFilterChange = (e: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    setFilters(filters => ({ ...filters, tenant: e.detail.value }))
  }

  const handleNavigate = (path: string) => {
    navigate(path)
  }

  return (
    <Flex direction="column" className={styles.container}>
      <PageBackToTop scrollTopOffset={120} onChangeState={setHideSubHeader} />
      <DashboardNavigation />

      <div
        className={clsx(styles.viewContainer, {
          [styles.scroll]: true,
        })}
      >
        <Flex direction="row" justify="between" className={styles.filtersContainer}>
          <Flex gap={12}>
            <WppInput
              size="s"
              name="search"
              placeholder={t('dashboard.field_search_placeholder')!}
              onWppChange={e => setSearchDebounced(e.detail.value || '')}
              type="search"
              data-testid="dashboard-project-search"
              className={styles.searchInput}
            />

            <TenantFilterSelect tenantIds={filter.tenant ?? []} onChange={handleWorkspaceFilterChange} />

            <WppSegmentedControl size="s" variant="icon" value={viewType}>
              <WppSegmentedControlItem
                variant="icon"
                value={ViewType.CARDS}
                data-testid="projects-card-view-button"
                onClick={() => handleNavigate(routesManager.projects.cards.pattern)}
              >
                <WppIconDataViewCards />
              </WppSegmentedControlItem>

              <WppSegmentedControlItem
                variant="icon"
                value={ViewType.TABLE}
                data-testid="projects-table-view-button"
                onClick={() => handleNavigate(routesManager.projects.table.pattern)}
              >
                <WppIconDataViewList />
              </WppSegmentedControlItem>
              <WppSegmentedControlItem
                variant="icon"
                value={ViewType.TIMELINE}
                size="s"
                data-testid="projects-timeline-view-button"
                onClick={() => handleNavigate(routesManager.projects.timeline.pattern)}
              >
                <WppIconBarHorizontal />
              </WppSegmentedControlItem>
            </WppSegmentedControl>
            <WppFilterButton
              counter={filtersCounts}
              onClick={() =>
                showProjectFilterModal({
                  filter,
                  attributes: customSelectAttributes || [],
                  handleCloseModal: handleCloseFilterModal,
                })
              }
            >
              {t('dashboard.btn_filter')!}
            </WppFilterButton>
          </Flex>
          <Flex gap={12}>
            {projectsExist && (
              <WppActionButton variant="secondary" onClick={() => showProjectsViewSettingsModal()}>
                <WppIconEye slot="icon-start" /> {t('dashboard.view_settings')}
              </WppActionButton>
            )}
            {canCreateProject && (projectsExist || isFilterActive) && (
              <WppButton size="s" onClick={() => showCreateProjectModal()} data-testid="create-new-project">
                <WppIconPlus slot="icon-start" /> {t('dashboard.btn_add_project')}
              </WppButton>
            )}
          </Flex>
        </Flex>

        <Routes>
          <Route
            path="/"
            element={
              viewType === ViewType.CARDS ? (
                <Navigate to={routesManager.projects.cards.shortPattern} replace />
              ) : viewType === ViewType.TABLE ? (
                <Navigate to={routesManager.projects.table.shortPattern} replace />
              ) : (
                <Navigate to={routesManager.projects.timeline.shortPattern} replace />
              )
            }
          />

          <Route
            path={routesManager.projects.cards.shortPattern}
            element={
              <OnRouteEnter onEnter={() => setViewType(ViewType.CARDS)}>
                <ProjectCardView filter={filter} setProjectsExist={setProjectsExist} settings={settings} />
              </OnRouteEnter>
            }
          />
          <Route
            path={routesManager.projects.table.shortPattern}
            element={
              <OnRouteEnter onEnter={() => setViewType(ViewType.TABLE)}>
                <ProjectsTableView filter={filter} setProjectsExist={setProjectsExist} settings={settings} />
              </OnRouteEnter>
            }
          />
          <Route
            path={routesManager.projects.timeline.shortPattern}
            element={
              <OnRouteEnter onEnter={() => setViewType(ViewType.TIMELINE)}>
                <ProjectsTimelineView filter={filter} setProjectsExist={setProjectsExist} setFilters={setFilters} />
              </OnRouteEnter>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Flex>
  )
}
