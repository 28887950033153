import { WppButton, WppDivider, WppEmpty404, WppTypography } from '@platform-ui-kit/components-library-react'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SideModalContentSkeleton } from 'components/common/sideModalSkeleton/SideModalContentSkeleton'
import { Truncate } from 'components/common/truncate/Truncate'
import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { useAssignMember } from 'hooks/useAssignMember'
import { useProject } from 'hooks/useProject'
import { AppIssueCard } from 'pages/components/appIssueCard/AppIssueCard'
import { ErrorState } from 'pages/components/templatePreviewModal/utils'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { AppDetailsModalActions } from 'pages/project/components/canvas/linearCanvas/components/item/linearApplication/detailsModal/AppDetailsModalActions'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { ApplicationItem } from 'types/projects/workflow'
import { isTaskStatusResolved } from 'utils/project'

interface Props {
  application?: ApplicationItem | null
  isLoading?: boolean
  isError?: boolean
  error?: AxiosError<unknown, any> | null
  onClose: () => void
  activityId?: string
  phaseId?: string
}

export const AppDetailsModalContent = ({
  application,
  isLoading,
  isError,
  error,
  onClose,
  activityId,
  phaseId,
}: Props) => {
  const { useExternalStatuses } = useProject() ?? {}
  const assignMember = useAssignMember(application?.assignUser)
  const { t } = useTranslation()
  const isResolved = !!application?.task && isTaskStatusResolved(application.task)

  const selectedVersion = useMemo(
    () =>
      application && application.externalAppVersions?.find(version => version.id === application.externalAppVersionId),
    [application],
  )

  const appLaunchErrors = useAppLaunchErrors(application!)

  return (
    <>
      {isLoading ? (
        <SideModalContentSkeleton />
      ) : (
        <>
          <WppTypography slot="header" type="2xl-heading">
            {t('modals.app_details_modal.title')}
          </WppTypography>

          <Flex slot="body" direction="column" gap={12} className={styles.tasksContainer}>
            {isError ? (
              <ErrorState error={error} />
            ) : (
              <>
                {!!appLaunchErrors.length && (
                  <Flex direction="column" gap={12} className="wpp-spacing-12-bottom">
                    {appLaunchErrors.map(appError => (
                      <AppIssueCard key={appError.issue} issueType={appError.issue} missingCtx={appError.missedCtx} />
                    ))}
                  </Flex>
                )}

                {application ? (
                  <Flex direction="column" gap={10}>
                    <WppTypography type="l-strong" data-testid="app-name">
                      {application.name}
                    </WppTypography>
                    <Flex direction="column" gap={12}>
                      <Flex align="center" className={styles.infoBlock}>
                        <WppTypography type="s-strong" className={styles.title}>
                          {t('modals.phase_details.status')}
                        </WppTypography>
                        <WppTypography type="s-strong" className={styles.greyColor900} data-testid="app-task-status">
                          {useExternalStatuses
                            ? application.task?.wrike?.externalStatus!
                            : t(`project.tasks.status.${application.task!.status}`)!}
                        </WppTypography>
                      </Flex>
                      <Flex align="center" className={styles.infoBlock}>
                        <WppTypography type="s-strong" className={styles.title}>
                          {t('modals.phase_details.assignee')}
                        </WppTypography>
                        {!assignMember ? (
                          <WppTypography type="s-strong" className={styles.greyColor500}>
                            {t('modals.phase_details.no_assignee')}
                          </WppTypography>
                        ) : (
                          <ResponsiblePerson
                            assignMember={assignMember}
                            size="xs"
                            data-testid="application-item-assignee"
                            showName
                          />
                        )}
                      </Flex>
                      <Flex align="center" className={styles.infoBlock}>
                        <WppTypography type="s-strong" className={styles.title}>
                          {t('modals.phase_details.due_date')}
                        </WppTypography>
                        {application?.startDate || application?.endDate ? (
                          <Calendar
                            startDate={application?.startDate}
                            endDate={application.endDate}
                            data-testid="application-item-dates"
                            resolved={isResolved}
                          />
                        ) : (
                          <WppTypography type="s-strong" className={styles.greyColor500}>
                            {t('modals.phase_details.no_due_date')}
                          </WppTypography>
                        )}
                      </Flex>

                      {application.description && (
                        <Flex direction="column">
                          <WppTypography type="s-strong" className={styles.title}>
                            {t('modals.phase_details.description')}
                          </WppTypography>
                          <Truncate
                            lines={3}
                            type="s-body"
                            className={styles.greyColor1000}
                            data-testid="description"
                            title={application.description}
                          >
                            {application.description}
                          </Truncate>
                        </Flex>
                      )}
                    </Flex>

                    <WppDivider />

                    {selectedVersion && (
                      <Flex direction="column" className={styles.versionWrapper}>
                        <WppTypography type="s-midi" className={styles.greyColor800}>
                          {t('modals.app_details_modal.app_version_title')}
                        </WppTypography>
                        <WppTypography type="s-strong" className={styles.greyColor1000}>
                          {selectedVersion.name}
                        </WppTypography>
                      </Flex>
                    )}
                  </Flex>
                ) : (
                  <Flex
                    justify="center"
                    align="center"
                    className={styles.noResultsContainer}
                    direction="column"
                    gap={24}
                  >
                    <WppEmpty404 width={120} />
                    <Flex justify="center" align="center" direction="column" gap={8}>
                      <WppTypography type="l-strong" className={styles.greyColor1000}>
                        {t('modals.errors.404.title')}
                      </WppTypography>
                      <WppTypography type="xs-body" className={styles.greyColor800}>
                        {t('modals.errors.404.description')}
                      </WppTypography>
                    </Flex>
                  </Flex>
                )}
              </>
            )}
          </Flex>
          <Flex slot="actions" justify="end" gap={12}>
            {application ? (
              <AppDetailsModalActions
                application={application}
                activityId={activityId}
                phaseId={phaseId}
                onClose={onClose}
              />
            ) : (
              <WppButton variant="primary" size="m" onClick={onClose}>
                {t('common.btn_done')}
              </WppButton>
            )}
          </Flex>
        </>
      )}
    </>
  )
}
