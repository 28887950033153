import { projectApi } from 'api'
import { Template } from 'types/projects/template'

export interface PostWorkflowTemplate extends Pick<Template, 'name' | 'tags' | 'description' | 'types'> {
  projectId?: Template['projectId']
  processType?: Template['processType']
  workflowEditable?: Template['workflowEditable']
}

export const createWorkflowTemplateApi = (body: PostWorkflowTemplate) => projectApi.post<Template>('/templates', body)
