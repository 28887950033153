import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useCallback } from 'react'

import { ACTION_ANALYTICS, ANALYTICS_SOURCE_ORCHESTRATION } from 'constants/analytics'
import { Members } from 'types/members/members'
import { Project } from 'types/projects/projects'
import { isDevelop, noop } from 'utils/common'

// Utility function to get common project analytics data
export const getCommonProjectAnalyticsData = (project: Project, me?: Members) => {
  return {
    project_id: project.id,
    workspace_name: project.tenant?.name,
    workspace_type: project.tenant?.tenantType,
    project_type: project.type,
    process_type: project.processType,
    project_hierarchy: project.contextHierarchy,
    ...(me && {
      user_agency: me?.agency,
      user_role: me?.role,
    }),
  }
}

export const useTrackAction = () => {
  const { osApi } = useOs()
  const { analytics } = osApi

  const trackAction = useCallback(
    (action: ACTION_ANALYTICS, payload: Record<string, any>) => {
      const enhancedPayload = {
        ...payload,
        source: ANALYTICS_SOURCE_ORCHESTRATION,
      }
      isDevelop
        ? noop()
        : analytics.track({
            type: AnalyticsActionType.action,
            payload: {
              action,
              params: Object.entries(enhancedPayload).map(([key, value]) => ({ key, value })),
            },
          })
    },
    [analytics],
  )

  return { trackAction }
}
