import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

import { ACTION_ANALYTICS } from 'constants/analytics'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { ProjectRole } from 'types/permissions/permissions'
import { Project } from 'types/projects/projects'

interface UseMembersAnalyticsProps {
  project: Project
}

type MembersAnalyticsActionType =
  | ACTION_ANALYTICS.ACTION_VIEW_PROJECT_MEMBERS
  | ACTION_ANALYTICS.ACTION_MEMBER_INVITE
  | ACTION_ANALYTICS.ACTION_MEMBER_INVITE_CANCELLED
  | ACTION_ANALYTICS.ACTION_VIEW_PROJECT_MEMBER_DETAILS
  | ACTION_ANALYTICS.ACTION_PROJECT_MEMBER_ROLE_CHANGED
  | ACTION_ANALYTICS.ACTION_PROJECT_MEMBER_REMOVED

export const useMembersAnalytics = ({ project }: UseMembersAnalyticsProps) => {
  const { trackAction } = useTrackAction()
  const {
    osContext: { userDetails },
  } = useOs()

  const getMeFromProjectMembers = useMemo(() => {
    return project.members?.find(({ email }) => email === userDetails?.email)
  }, [project.members, userDetails?.email])

  const commonAnalyticsData = useMemo(() => getCommonProjectAnalyticsData(project), [project])

  const buildAnalyticsData = useCallback(
    (overrides?: Partial<Project>) => ({
      ...commonAnalyticsData,
      user_agency: getMeFromProjectMembers?.agency,
      user_role: getMeFromProjectMembers?.role,
      process_type: overrides?.processType || project.processType,
    }),
    [commonAnalyticsData, getMeFromProjectMembers, project.processType],
  )

  const trackActionWithAnalyticsData = useCallback(
    (actionType: MembersAnalyticsActionType, additionalData: Record<string, any> = {}) => {
      trackAction(actionType, { ...buildAnalyticsData(), ...additionalData })
    },
    [trackAction, buildAnalyticsData],
  )

  const trackViewProjectMembers = useCallback(() => {
    trackActionWithAnalyticsData(ACTION_ANALYTICS.ACTION_VIEW_PROJECT_MEMBERS)
  }, [trackActionWithAnalyticsData])

  const trackMemberInvite = useCallback(
    ({
      totalNumberOfMembers,
      totalNumberOfExternalMembers,
      invitedMembersRoles,
      calculateTimeDifferenceInSeconds,
    }: {
      totalNumberOfMembers: number
      totalNumberOfExternalMembers: number
      invitedMembersRoles: ProjectRole
      calculateTimeDifferenceInSeconds: () => number
    }) => {
      trackActionWithAnalyticsData(ACTION_ANALYTICS.ACTION_MEMBER_INVITE, {
        duration_in_seconds: calculateTimeDifferenceInSeconds(),
        totalNumberOfMembers,
        totalNumberOfExternalMembers,
        invitedMembersRoles,
      })
    },
    [trackActionWithAnalyticsData],
  )

  const trackMemberInviteCancelled = useCallback(
    (calculateTimeDifferenceInSeconds: () => number) => {
      trackActionWithAnalyticsData(ACTION_ANALYTICS.ACTION_MEMBER_INVITE_CANCELLED, {
        duration_in_seconds: calculateTimeDifferenceInSeconds(),
      })
    },
    [trackActionWithAnalyticsData],
  )

  const trackViewProjectMemberDetails = useCallback(
    ({
      memberType,
      agency,
      role,
    }: {
      memberType: 'internal' | 'external' | 'wrike_record'
      agency?: string
      role: string
    }) => {
      trackActionWithAnalyticsData(ACTION_ANALYTICS.ACTION_VIEW_PROJECT_MEMBER_DETAILS, {
        member_type: memberType,
        member_agency: agency,
        member_role: role,
      })
    },
    [trackActionWithAnalyticsData],
  )

  const trackProjectMemberRoleChanged = useCallback(
    (role: ProjectRole) => {
      trackActionWithAnalyticsData(ACTION_ANALYTICS.ACTION_PROJECT_MEMBER_ROLE_CHANGED, { member_role: role })
    },
    [trackActionWithAnalyticsData],
  )

  const trackProjectMemberRemoved = useCallback(
    (nrOfMembersRemoved: number) => {
      trackActionWithAnalyticsData(ACTION_ANALYTICS.ACTION_PROJECT_MEMBER_REMOVED, {
        nr_of_members_removed: nrOfMembersRemoved,
      })
    },
    [trackActionWithAnalyticsData],
  )

  return {
    trackViewProjectMembers,
    trackMemberInvite,
    trackMemberInviteCancelled,
    trackViewProjectMemberDetails,
    trackProjectMemberRoleChanged,
    trackProjectMemberRemoved,
  }
}
