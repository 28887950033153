import { useMemo } from 'react'

import { useIsPermitted } from 'hooks/useIsPermitted'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions } from 'types/permissions/permissions'
import { projectTabs } from 'utils/project'
import { routesManager } from 'utils/routesManager'

export function useProjectTabs({ isEditable }: { isEditable?: boolean }) {
  const { isPermitted } = useIsPermitted()
  const { hasRole, isResponsible } = useHasProjectRole()

  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  return useMemo(() => {
    if (isGlobalManage) return projectTabs

    return projectTabs.filter(({ id, permissions, roles, forResponsible }) => {
      // Check if permissions allow access
      if (permissions && !isPermitted(permissions)) return false

      // Only show "Canvas" tab if isEditable is true
      if (id === routesManager.project.canvas.shortPattern && !isEditable) return false

      // Check if roles and responsibility conditions allow access
      if (forResponsible && isResponsible()) return true
      return !roles || hasRole(roles)
    })
  }, [hasRole, isGlobalManage, isPermitted, isResponsible, isEditable])
}
