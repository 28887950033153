import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { useFetchNavigationNodeParents } from 'api/tenant/queries/useFetchNavigationNodeParents'
import { Project } from 'types/projects/projects'
import { resolveHierarchyPath } from 'utils/coreContextValues'

export const useIsWorkspaceValid = ({ project }: { project: Project }) => {
  const {
    osContext: { tenant },
  } = useOs()

  const { data: projectTree } = useFetchNavigationNodeParents({
    params: { tenantId: tenant.id, nodeId: project.contextWorkspace! },
    enabled: !!project.contextWorkspace,
    staleTime: 60 * 1000,
  })

  return useMemo(() => {
    if (!!project.contextWorkspace && projectTree) {
      const projectHierarchyIds = resolveHierarchyPath({
        validHierarchyId: project.contextWorkspace ?? null,
        navigationTree: projectTree!,
      })

      return !!projectHierarchyIds.length
    }

    return true
  }, [project.contextWorkspace, projectTree])
}
